import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";

import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { UserService } from "@providers/services";

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(private _router: Router, private _userService: UserService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(this.formatError.bind(this)));
  }

  formatError(err: HttpErrorResponse): Observable<any> {
    const { status, error } = err;

    if (status === 401) {
      this._userService.removeUser();
      this._router.navigate(["/login"]);
    }

    return throwError(error);
  }
}
