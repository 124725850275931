import { Injectable } from "@angular/core";

import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class JwtService {
  getToken(): string {
    return JSON.parse(localStorage.getItem("universal:auth_token"));
  }

  setToken(token: string): void {
    localStorage.setItem("universal:auth_token", JSON.stringify(token));
  }

  removeToken(): void {
    localStorage.removeItem("universal:auth_token");
  }

  decodeToken(): any {
    return jwt_decode(localStorage.getItem("universal:auth_token"));
  }

  hasExpired(): boolean {
    const { exp } = this.decodeToken();
    const isExpired = new Date().getTime() > exp * 1000;
    return isExpired;
  }
}
