import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MaterialModule } from "src/app/shared";

import { ConfirmDialogComponent } from "./confirm-dialog.component";

@NgModule({
  declarations: [ConfirmDialogComponent],
  imports: [CommonModule, MaterialModule],
  exports: [ConfirmDialogComponent],
})
export class ConfirmDialogModule {}
