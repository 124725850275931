import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";

import { CommentDialogComponent } from "./comment-dialog.component";

@NgModule({
  declarations: [CommentDialogComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDialogModule, MatInputModule],
  exports: [CommentDialogComponent],
})
export class CommentDialogModule {}
