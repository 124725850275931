const TITLES = {
  dashboard: "Dashboard | Universal Law Private Affairs System",
  "spc-process": "Secured Party Creditor Products | Universal Law Private Affairs System",
  "trust-process": "Trust Products | Universal Law Private Affairs System",
  "services.master-bond": "Master Bond Services | Universal Law Private Affairs System",
  "services.tax-settlement": "Tax Settlement Services | Universal Law Private Affairs System",
  settings: "System Settings | Universal Law Private Affairs System",
  "user-management": "Admin Users and Roles | Universal Law Private Affairs System",
};

export const getPageTitle = (url: string) => {
  const arr = url
    .split("/")
    .slice(1, 3)
    .map((el) => (el.includes("?") ? el.split("?") : el));

  let key;

  if (arr.length === 1) {
    [key] = arr;
  }

  if (arr.includes("spc-process")) {
    key = "spc-process";
  }

  if (arr.includes("trust-process")) {
    key = "trust-process";
  }

  if (arr.includes("services")) {
    key = arr.join(".");
  }

  if (arr.includes("settings")) {
    key = "settings";
  }

  if (arr.includes("user-management")) {
    key = "user-management";
  }

  return TITLES[key] || "Universal Law Private Affairs System";
};
