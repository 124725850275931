import {
  Sku,
  InstructionProductName,
  TemplateProductName,
  SPCStatus,
  TrustStatus,
  NNCStatus,
  TaxProductStatus,
  GsaBondProductStatus,
  EasyLienV2ProductStatus,
  MasterBondProductStatus,
  NDAStatus,
  IrvicStatus,
} from "../enums";

export const NO_CB = () => {};

export const UPLOAD_FILE_MAX_SIZE = 90 * 1024 * 1024;

// time delay
export const SHORT_DELAY_MILLISECONDS = 250;
export const LONG_DELAY_MILLISECONDS = 3 * 1000;

export const TEMPLATES = [
  {
    label: "SPC USC Template",
    value: {
      sku: Sku.SPC,
      productName: TemplateProductName.SPC_USC,
    },
  },
  {
    label: "SPC USPR Template",
    value: {
      sku: Sku.SPC,
      productName: TemplateProductName.SPC_USPR,
    },
  },
  {
    label: "Legal Notice Demand",
    value: {
      sku: Sku.SPC,
      productName: TemplateProductName.LEGAL_NOTICE_DEMAND,
    },
  },
  {
    label: "SPC Consent Template",
    value: {
      sku: Sku.SPC_CONSENT,
    },
  },
  {
    label: "SPC Addendum Template",
    value: {
      sku: Sku.SPC_AMENDMENT,
    },
  },
  {
    label: "SPC Treasury Letter Template",
    value: {
      sku: Sku.TREASURY_PACKET,
    },
  },
  {
    label: "Master Bond UCC Template",
    value: {
      sku: Sku.MASTER_BOND,
      productName: TemplateProductName.MASTER_BOND,
    },
  },
  {
    label: "Master Bond P Template",
    value: {
      sku: Sku.MASTER_BOND,
      productName: TemplateProductName.MASTER_BOND_P,
    },
  },
  {
    label: "Master Bond Cover Letter Template",
    value: {
      sku: Sku.MASTER_BOND_COVER_LETTER,
    },
  },
  {
    label: "Tax Settlement Template",
    value: {
      sku: Sku.TAX,
      productName: TemplateProductName.TAX_SETTLEMENT,
    },
  },
  // {
  //   label: "Tax Settlement Bond Template",
  //   value: {
  //     sku: Sku.TAX,
  //     productName: TemplateProductName.BOND_SETTLEMENT,
  //   },
  // },
  // {
  //   label: "Tax Bill of Exchange Template",
  //   value: {
  //     sku: Sku.TAX,
  //     productName: TemplateProductName.TAX_BILL_OF_EXCHANGE,
  //   },
  // },
  // {
  //   label: "Tax Private Indemnity Bond Template",
  //   value: {
  //     sku: Sku.TAX,
  //     productName: TemplateProductName.TAX_PRIVATE_INDEMNITY_BOND,
  //   },
  // },
  // {
  //   label: "Tax Private Bill of Exchange Template",
  //   value: {
  //     sku: Sku.TAX,
  //     productName: TemplateProductName.TAX_PRIVATE_BILL_OF_EXCHANGE,
  //   },
  // },
  {
    label: "Certificate of Mailings",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.CERT_OF_MAILINGS,
    },
  },
  {
    label: "New Tax",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.NEW_TAX,
    },
  },
  {
    label: "New Tax 2",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.NEW_TAX_2,
    },
  },
  {
    label: "Tax Settlement Bond Template",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.TAX_SETTLEMENT_BOND,
    },
  },
  {
    label: "Tax Bill of Exchange Template",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.TAX_BILL_EXCHANGE,
    },
  },
  {
    label: "Tax Private Indemnity Bond Template",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.TAX_PRIVATE_INDEMNITY_BOND,
    },
  },
  {
    label: "Tax Private Bill of Exchange Template",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.TAX_PRIVATE_BILL_OF_EXCHANGE,
    },
  },
  {
    label: "Tax UCC",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.MASTER_TAX_UCC,
    },
  },
  {
    label: "Bill of Exchange Treasury Cover Letter",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.BOE_TREASURY_COVER_LETTER,
    },
  },
  {
    label: "Private Indemnity Bond Treasury Cover Letter",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.PIB_TREASURY_COVER_LETTER,
    },
  },
  {
    label: "Bill of Exchange Attachment A",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.BOE_ATTACHMENT_A,
    },
  },
  {
    label: "Bill of Exchange Attachment B",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.BOE_ATTACHMENT_B,
    },
  },
  {
    label: "Private Indemnity Bond Attachment A",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.PIB_ATTACHMENT_A,
    },
  },
  {
    label: "Private Indemnity Bond Attachment B",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.PIB_ATTACHMENT_B,
    },
  },
  {
    label: "F1040ES",
    value: {
      sku: Sku.TAX_V2_PDF,
      productName: TemplateProductName.F1040ES,
    },
  },
  {
    label: "F1040v",
    value: {
      sku: Sku.TAX_V2_PDF,
      productName: TemplateProductName.F1040v,
    },
  },
  {
    label: "GSA Cover Letter",
    value: {
      sku: Sku.GSA_V2_DOCX,
      productName: TemplateProductName.GSA_COVER_LETTER,
    },
  },
  {
    label: "GSA Judicial Review",
    value: {
      sku: Sku.GSA_V2_DOCX,
      productName: TemplateProductName.GSA_JUDICIAL_REVIEW,
    },
  },
  {
    label: "GSA Declaration of Facts",
    value: {
      sku: Sku.GSA_V2_DOCX,
      productName: TemplateProductName.GSA_DECLARATION_OF_FACTS,
    },
  },
  {
    label: "GSA Fiduciary Contract District Court",
    value: {
      sku: Sku.GSA_V2_DOCX,
      productName: TemplateProductName.GSA_FIDUCIARY_CONTRACT_DISTRICT_COURT,
    },
  },
  {
    label: "GSA Fiduciary Contract Irs",
    value: {
      sku: Sku.GSA_V2_DOCX,
      productName: TemplateProductName.GSA_FIDUCIARY_CONTRACT_IRS,
    },
  },
  {
    label: "GSA Fiduciary Contract Dtc",
    value: {
      sku: Sku.GSA_V2_DOCX,
      productName: TemplateProductName.GSA_FIDUCIARY_CONTRACT_DTC,
    },
  },
  {
    label: "GSA Fiduciary Contract Treasury",
    value: {
      sku: Sku.GSA_V2_DOCX,
      productName: TemplateProductName.GSA_FIDUCIARY_CONTRACT_TREASURY,
    },
  },
  {
    label: "GSA W9 Cover Letter",
    value: {
      sku: Sku.GSA_V2_DOCX,
      productName: TemplateProductName.W9_COVER_LETTER,
    },
  },
  {
    label: "Affidavit of Ownership",
    value: {
      sku: Sku.GSA_V2_DOCX,
      productName: TemplateProductName.AFFIDAVIT_OF_OWNERSHIP,
    },
  },
  {
    label: "GSA Appendix1",
    value: {
      sku: Sku.GSA_V2_PDF,
      productName: TemplateProductName.APPENDIX1,
    },
  },
  {
    label: "GSA Appendix2",
    value: {
      sku: Sku.GSA_V2_PDF,
      productName: TemplateProductName.APPENDIX2,
    },
  },
  {
    label: "GSA Appendix3",
    value: {
      sku: Sku.GSA_V2_PDF,
      productName: TemplateProductName.APPENDIX3,
    },
  },
  {
    label: "GSA F56",
    value: {
      sku: Sku.GSA_V2_PDF,
      productName: TemplateProductName.F56,
    },
  },
  {
    label: "GSA W9",
    value: {
      sku: Sku.GSA_V2_PDF,
      productName: TemplateProductName.W9,
    },
  },
  {
    label: "GSA F1040V",
    value: {
      sku: Sku.GSA_V2_PDF,
      productName: TemplateProductName.F1040V,
    },
  },
  {
    label: "GSA SF24",
    value: {
      sku: Sku.GSA_V2_PDF,
      productName: TemplateProductName.SF24,
    },
  },
  {
    label: "GSA SF25",
    value: {
      sku: Sku.GSA_V2_PDF,
      productName: TemplateProductName.SF25,
    },
  },
  {
    label: "GSA SF25A",
    value: {
      sku: Sku.GSA_V2_PDF,
      productName: TemplateProductName.SF25A,
    },
  },
  {
    label: "GSA SF28",
    value: {
      sku: Sku.GSA_V2_PDF,
      productName: TemplateProductName.SF28,
    },
  },
  {
    label: "GSA OF90",
    value: {
      sku: Sku.GSA_PDF,
      productName: TemplateProductName.OF90,
    },
  },
  {
    label: "GSA OF91",
    value: {
      sku: Sku.GSA_V2_PDF,
      productName: TemplateProductName.OF91,
    },
  },
  {
    label: "GSA UCC 1",
    value: {
      sku: Sku.GSA_V2_DOCX,
      productName: TemplateProductName.GSA_UCC1,
    },
  },
  {
    label: "Proof Of Service",
    value: {
      sku: Sku.GSA_V2_DOCX,
      productName: TemplateProductName.PROOF_OF_SERVICE,
    },
  },
  {
    label: "Demand For Closure Of Case",
    value: {
      sku: Sku.GSA_V2_DOCX,
      productName: TemplateProductName.SETTLEMENT_OF_LITIGATION,
    },
  },
  {
    label: "Notice of Exemption",
    value: {
      sku: Sku.EASY_LIEN,
      productName: TemplateProductName.NOTICE_EXEMPTION,
    },
  },
  {
    label: "Notice of Exemption Offense",
    value: {
      sku: Sku.EASY_LIEN,
      productName: TemplateProductName.NOTICE_EXEMPTION_OFFENSE,
    },
  },
  {
    label: "Notice of Exemption Flexible Offense",
    value: {
      sku: Sku.EASY_LIEN,
      productName: TemplateProductName.NOTICE_EXEMPTION_FLEXIBLE,
    },
  },
  {
    label: "Notice of Default",
    value: {
      sku: Sku.EASY_LIEN,
      productName: TemplateProductName.NOTICE_DEFAULT,
    },
  },
  {
    label: "Notice of Lien",
    value: {
      sku: Sku.EASY_LIEN,
      productName: TemplateProductName.NOTICE_LIEN,
    },
  },
  {
    label: "Easy Lien Filling",
    value: {
      sku: Sku.EASY_LIEN,
      productName: TemplateProductName.LIEN_FILLING,
    },
  },
  {
    label: "Trust Template",
    value: {
      sku: Sku.TRUST,
    },
  },
  {
    label: "Beneficiary Trust Template",
    value: {
      sku: Sku.BENEFICIARY_TRUST,
    },
  },
  {
    label: "Protector Amendment Template",
    value: {
      sku: Sku.CHANGE_AMENDMENT,
      productName: TemplateProductName.PROTECTOR_AMENDMENT,
    },
  },
  {
    label: "Trustee Amendment Template",
    value: {
      sku: Sku.CHANGE_AMENDMENT,
      productName: TemplateProductName.TRUSTEE_AMENDMENT,
    },
  },
  {
    label: "Beneficiary Appointment Template",
    value: {
      sku: Sku.CHANGE_AMENDMENT,
      productName: TemplateProductName.BENEFICIARY_APPOINT,
    },
  },
  {
    label: "Beneficiary Removal Template",
    value: {
      sku: Sku.CHANGE_AMENDMENT,
      productName: TemplateProductName.BENEFICIARY_REMOVAL,
    },
  },
  {
    label: "Beneficiary Amendment Template",
    value: {
      sku: Sku.CHANGE_AMENDMENT,
      productName: TemplateProductName.BENEFICIARY_AMEND,
    },
  },
  {
    label: "Trust Wallet",
    value: {
      sku: Sku.TRUST_WALLET,
    },
  },
  {
    label: "Add Trust Property",
    value: {
      sku: Sku.TRUST_PROPERTY,
      productName: TemplateProductName.TRUST_ADD_PROPERTY,
    },
  },
  {
    label: "Trust Property Filling",
    value: {
      sku: Sku.TRUST_PROPERTY,
      productName: TemplateProductName.TRUST_PROPERTY_FILLING,
    },
  },
  {
    label: "Notice Of Non-Consent Judge",
    value: {
      sku: Sku.NNC,
      productName: TemplateProductName.NNC_JUDGE,
    },
  },
  {
    label: "Notice Of Non-Consent Clerk",
    value: {
      sku: Sku.NNC,
      productName: TemplateProductName.NNC_CLERK,
    },
  },
  {
    label: "NCC Exhibit A",
    value: {
      sku: Sku.NNC_PDF,
      productName: TemplateProductName.NNC_EXHIBIT_A,
    },
  },
  {
    label: "NCC Exhibit B",
    value: {
      sku: Sku.NNC_PDF,
      productName: TemplateProductName.NNC_EXHIBIT_A,
    },
  },
  {
    label: "Easy Lien Notice of Copyright",
    value: {
      sku: Sku.EASY_LIEN_V2,
      productName: TemplateProductName.ELIENV2_COPYRIGHT,
    },
  },
  {
    label: "Easy Lien Notice of Default",
    value: {
      sku: Sku.EASY_LIEN_V2,
      productName: TemplateProductName.ELIENV2_DEFAULT,
    },
  },
  {
    label: "Easy Lien Notice of Lien",
    value: {
      sku: Sku.EASY_LIEN_V2,
      productName: TemplateProductName.ELIENV2_LIEN,
    },
  },
  {
    label: "Easy Lien Filling",
    value: {
      sku: Sku.EASY_LIEN_V2,
      productName: TemplateProductName.ELIENV2_FILLING,
    },
  },
  {
    label: "Easy Lien Exhibit A",
    value: {
      sku: Sku.EASY_LIEN_V2,
      productName: TemplateProductName.ELIENV2_EXHIBIT_A,
    },
  },
  {
    label: "Easy Lien Exhibit B",
    value: {
      sku: Sku.EASY_LIEN_V2,
      productName: TemplateProductName.ELIENV2_EXHIBIT_B,
    },
  },
  {
    label: "Easy Lien Exhibit C",
    value: {
      sku: Sku.EASY_LIEN_V2,
      productName: TemplateProductName.ELIENV2_EXHIBIT_C,
    },
  },
  {
    label: "Easy Lien Exhibit D",
    value: {
      sku: Sku.EASY_LIEN_V2,
      productName: TemplateProductName.ELIENV2_EXHIBIT_D,
    },
  },
  {
    label: "Easy Lien Exhibit E",
    value: {
      sku: Sku.EASY_LIEN_V2,
      productName: TemplateProductName.ELIENV2_EXHIBIT_E,
    },
  },
  {
    label: "Easy Lien Exhibit F",
    value: {
      sku: Sku.EASY_LIEN_V2,
      productName: TemplateProductName.ELIENV2_EXHIBIT_F,
    },
  },
];

export const NEW_TEMPLATES = [
  {
    label: "SPC",
    value: [
      {
        label: "SPC USC",
        value: {
          sku: Sku.SPC,
          productName: TemplateProductName.SPC_USC,
        },
      },
      {
        label: "SPC USC True",
        value: {
          sku: Sku.SPC,
          productName: TemplateProductName.SPC_USC_TRUE,
        },
      },
      {
        label: "SPC USPR",
        value: {
          sku: Sku.SPC,
          productName: TemplateProductName.SPC_USPR,
        },
      },
      {
        label: "SPC USPR True",
        value: {
          sku: Sku.SPC,
          productName: TemplateProductName.SPC_USPR_TRUE,
        },
      },
      {
        label: "Legal Notice Demand",
        value: {
          sku: Sku.SPC,
          productName: TemplateProductName.LEGAL_NOTICE_DEMAND,
        },
      },
      {
        label: "SPC UCC-1",
        value: {
          sku: Sku.SPC,
          productName: TemplateProductName.GSA_UCC1,
        },
      },
      {
        label: "SPC UCC-3",
        value: {
          sku: Sku.SPC_AMENDMENT,
          productName: TemplateProductName.GSA_UCC1,
        },
      },
      {
        label: "SPC Consent",
        value: {
          sku: Sku.SPC_CONSENT,
        },
      },
      {
        label: "SPC Addendum Template",
        value: {
          sku: Sku.SPC_AMENDMENT,
        },
      },
    ],
  },
  {
    label: "Trust",
    value: [
      {
        label: "Trust",
        value: {
          sku: Sku.TRUST,
        },
      },
      {
        label: "Beneficiary Trust",
        value: {
          sku: Sku.BENEFICIARY_TRUST,
        },
      },
      {
        label: "Protector Amendment",
        value: {
          sku: Sku.CHANGE_AMENDMENT,
          productName: TemplateProductName.PROTECTOR_AMENDMENT,
        },
      },
      {
        label: "Trustee Amendment",
        value: {
          sku: Sku.CHANGE_AMENDMENT,
          productName: TemplateProductName.TRUSTEE_AMENDMENT,
        },
      },
      {
        label: "Beneficiary Appointment",
        value: {
          sku: Sku.CHANGE_AMENDMENT,
          productName: TemplateProductName.BENEFICIARY_APPOINT,
        },
      },
      {
        label: "Beneficiary Removal",
        value: {
          sku: Sku.CHANGE_AMENDMENT,
          productName: TemplateProductName.BENEFICIARY_REMOVAL,
        },
      },
      {
        label: "Beneficiary Amendment",
        value: {
          sku: Sku.CHANGE_AMENDMENT,
          productName: TemplateProductName.BENEFICIARY_AMEND,
        },
      },
      {
        label: "Trust Wallet",
        value: {
          sku: Sku.TRUST_WALLET,
        },
      },
      {
        label: "Add Trust Property",
        value: {
          sku: Sku.TRUST_PROPERTY,
          productName: TemplateProductName.TRUST_ADD_PROPERTY,
        },
      },
      {
        label: "Trust Property Filling",
        value: {
          sku: Sku.TRUST_PROPERTY,
          productName: TemplateProductName.TRUST_PROPERTY_FILLING,
        },
      },
    ],
  },
  {
    label: "Master Bond",
    value: [
      {
        label: "Master Bond Cover Letter",
        value: {
          sku: Sku.MASTER_BOND_COVER_LETTER,
        },
      },
      {
        label: "Master Bond P",
        value: {
          sku: Sku.MASTER_BOND,
          productName: TemplateProductName.MASTER_BOND_P,
        },
      },
      {
        label: "Master Bond UCC",
        value: {
          sku: Sku.MASTER_BOND,
          productName: TemplateProductName.MASTER_BOND,
        },
      },
    ],
  },
  {
    label: "Tax Settlement",
    value: [
      {
        label: "Certificate of Mailings",
        value: {
          sku: Sku.TAX_V2_DOCX,
          productName: TemplateProductName.CERT_OF_MAILINGS,
        },
      },
      {
        label: "Tax Settlement Docs",
        value: {
          sku: Sku.TAX_V2_DOCX,
          productName: TemplateProductName.NEW_TAX,
        },
      },
      {
        label: "Regenerated Tax Docs",
        value: {
          sku: Sku.TAX_V2_DOCX,
          productName: TemplateProductName.NEW_TAX_2,
        },
      },
      {
        label: "BOE Attachment A",
        value: {
          sku: Sku.TAX_V2_DOCX,
          productName: TemplateProductName.BOE_ATTACHMENT_A,
        },
      },
      {
        label: "Tax Private Bill of Exchange",
        value: {
          sku: Sku.TAX_V2_DOCX,
          productName: TemplateProductName.TAX_PRIVATE_BILL_OF_EXCHANGE,
        },
      },
      {
        label: "BOE Attachment B",
        value: {
          sku: Sku.TAX_V2_DOCX,
          productName: TemplateProductName.BOE_ATTACHMENT_B,
        },
      },
      {
        label: "BOE UCC-1 Filing",
        value: {
          sku: Sku.TAX_V2_DOCX,
          productName: TemplateProductName.TAX_BILL_EXCHANGE,
        },
      },
      {
        label: "PIB Attachment A",
        value: {
          sku: Sku.TAX_V2_DOCX,
          productName: TemplateProductName.PIB_ATTACHMENT_A,
        },
      },
      {
        label: "Tax Private Indemnity Bond",
        value: {
          sku: Sku.TAX_V2_DOCX,
          productName: TemplateProductName.TAX_PRIVATE_INDEMNITY_BOND,
        },
      },
      {
        label: "PIB Attachment B",
        value: {
          sku: Sku.TAX_V2_DOCX,
          productName: TemplateProductName.PIB_ATTACHMENT_B,
        },
      },
      {
        label: "Tax PIB UCC-1 Filing",
        value: {
          sku: Sku.TAX_V2_DOCX,
          productName: TemplateProductName.TAX_SETTLEMENT_BOND,
        },
      },
      {
        label: "Tax F1040ES",
        value: {
          sku: Sku.TAX_V2_PDF,
          productName: TemplateProductName.F1040ES,
        },
      },
      {
        label: "Tax F1040v",
        value: {
          sku: Sku.TAX_V2_PDF,
          productName: TemplateProductName.F1040v,
        },
      },
      {
        label: "Master Tax UCC",
        value: {
          sku: Sku.TAX_V2_DOCX,
          productName: TemplateProductName.MASTER_TAX_UCC,
        },
      },
    ],
  },
  {
    label: "Notice of Non Consent",
    value: [
      {
        label: "Notice Of Non-Consent Judge",
        value: {
          sku: Sku.NNC,
          productName: TemplateProductName.NNC_JUDGE,
        },
      },
      {
        label: "Notice Of Non-Consent Clerk",
        value: {
          sku: Sku.NNC,
          productName: TemplateProductName.NNC_CLERK,
        },
      },
      {
        label: "NNC Exhibit A",
        value: {
          sku: Sku.NNC_PDF,
          productName: TemplateProductName.NNC_EXHIBIT_A,
        },
      },
      {
        label: "NNC Exhibit B",
        value: {
          sku: Sku.NNC_PDF,
          productName: TemplateProductName.NNC_EXHIBIT_A,
        },
      },
    ],
  },
  {
    label: "Lien",
    value: [
      {
        label: "Copyright Notice Notice 1",
        value: {
          sku: Sku.EASY_LIEN_V2,
          productName: TemplateProductName.ELIENV2_COPYRIGHT,
        },
      },
      {
        label: "Copyright Notice of Default",
        value: {
          sku: Sku.EASY_LIEN_V2,
          productName: TemplateProductName.ELIENV2_DEFAULT,
        },
      },
      {
        label: "Copyright Notice of Lien",
        value: {
          sku: Sku.EASY_LIEN_V2,
          productName: TemplateProductName.ELIENV2_LIEN,
        },
      },
      {
        label: "Lien UCC-1 Filing",
        value: {
          sku: Sku.EASY_LIEN_V2,
          productName: TemplateProductName.ELIENV2_FILLING,
        },
      },
      {
        label: "Lien Exhibit A",
        value: {
          sku: Sku.EASY_LIEN_V2,
          productName: TemplateProductName.ELIENV2_EXHIBIT_A,
        },
      },
      {
        label: "Lien Exhibit B",
        value: {
          sku: Sku.EASY_LIEN_V2,
          productName: TemplateProductName.ELIENV2_EXHIBIT_B,
        },
      },
      {
        label: "Lien Exhibit C",
        value: {
          sku: Sku.EASY_LIEN_V2,
          productName: TemplateProductName.ELIENV2_EXHIBIT_C,
        },
      },
      {
        label: "Lien Exhibit D",
        value: {
          sku: Sku.EASY_LIEN_V2,
          productName: TemplateProductName.ELIENV2_EXHIBIT_D,
        },
      },
      {
        label: "Lien Exhibit E",
        value: {
          sku: Sku.EASY_LIEN_V2,
          productName: TemplateProductName.ELIENV2_EXHIBIT_E,
        },
      },
      {
        label: "Lien Exhibit F",
        value: {
          sku: Sku.EASY_LIEN_V2,
          productName: TemplateProductName.ELIENV2_EXHIBIT_F,
        },
      },
      {
        label: "Mask Notice 1",
        value: {
          sku: Sku.EASY_LIEN,
          productName: TemplateProductName.NOTICE_EXEMPTION,
        },
      },
      {
        label: "Flexible Notice 1",
        value: {
          sku: Sku.EASY_LIEN,
          productName: TemplateProductName.NOTICE_EXEMPTION_FLEXIBLE,
        },
      },
      {
        label: "Notice of Default",
        value: {
          sku: Sku.EASY_LIEN,
          productName: TemplateProductName.NOTICE_DEFAULT,
        },
      },
      {
        label: "Notice of Lien",
        value: {
          sku: Sku.EASY_LIEN,
          productName: TemplateProductName.NOTICE_LIEN,
        },
      },
    ],
  },
  {
    label: "GSA Bond",
    value: [
      {
        label: "GSA Cover Letter",
        value: {
          sku: Sku.GSA_V2_DOCX,
          productName: TemplateProductName.GSA_COVER_LETTER,
        },
      },
      {
        label: "GSA Judicial Review",
        value: {
          sku: Sku.GSA_V2_DOCX,
          productName: TemplateProductName.GSA_JUDICIAL_REVIEW,
        },
      },
      {
        label: "GSA Declaration of Facts",
        value: {
          sku: Sku.GSA_V2_DOCX,
          productName: TemplateProductName.GSA_DECLARATION_OF_FACTS,
        },
      },
      {
        label: "GSA Fiduciary Contract District Court",
        value: {
          sku: Sku.GSA_V2_DOCX,
          productName: TemplateProductName.GSA_FIDUCIARY_CONTRACT_DISTRICT_COURT,
        },
      },
      {
        label: "GSA Fiduciary Contract Irs",
        value: {
          sku: Sku.GSA_V2_DOCX,
          productName: TemplateProductName.GSA_FIDUCIARY_CONTRACT_IRS,
        },
      },
      {
        label: "GSA Fiduciary Contract Dtc",
        value: {
          sku: Sku.GSA_V2_DOCX,
          productName: TemplateProductName.GSA_FIDUCIARY_CONTRACT_DTC,
        },
      },
      {
        label: "GSA Fiduciary Contract Treasury",
        value: {
          sku: Sku.GSA_V2_DOCX,
          productName: TemplateProductName.GSA_FIDUCIARY_CONTRACT_TREASURY,
        },
      },
      {
        label: "GSA W9 Cover Letter",
        value: {
          sku: Sku.GSA_V2_DOCX,
          productName: TemplateProductName.W9_COVER_LETTER,
        },
      },
      {
        label: "Affidavit of Ownership",
        value: {
          sku: Sku.GSA_V2_DOCX,
          productName: TemplateProductName.AFFIDAVIT_OF_OWNERSHIP,
        },
      },
      {
        label: "GSA Appendix1",
        value: {
          sku: Sku.GSA_V2_PDF,
          productName: TemplateProductName.APPENDIX1,
        },
      },
      {
        label: "GSA Appendix2",
        value: {
          sku: Sku.GSA_V2_PDF,
          productName: TemplateProductName.APPENDIX2,
        },
      },
      {
        label: "GSA Appendix3",
        value: {
          sku: Sku.GSA_V2_PDF,
          productName: TemplateProductName.APPENDIX3,
        },
      },
      {
        label: "GSA F56",
        value: {
          sku: Sku.GSA_V2_PDF,
          productName: TemplateProductName.F56,
        },
      },
      {
        label: "GSA W9",
        value: {
          sku: Sku.GSA_V2_PDF,
          productName: TemplateProductName.W9,
        },
      },
      {
        label: "GSA F1040V",
        value: {
          sku: Sku.GSA_V2_PDF,
          productName: TemplateProductName.F1040V,
        },
      },
      {
        label: "GSA SF24",
        value: {
          sku: Sku.GSA_V2_PDF,
          productName: TemplateProductName.SF24,
        },
      },
      {
        label: "GSA SF25",
        value: {
          sku: Sku.GSA_V2_PDF,
          productName: TemplateProductName.SF25,
        },
      },
      {
        label: "GSA SF25A",
        value: {
          sku: Sku.GSA_V2_PDF,
          productName: TemplateProductName.SF25A,
        },
      },
      {
        label: "GSA SF28",
        value: {
          sku: Sku.GSA_V2_PDF,
          productName: TemplateProductName.SF28,
        },
      },
      {
        label: "GSA OF90",
        value: {
          sku: Sku.GSA_PDF,
          productName: TemplateProductName.OF90,
        },
      },
      {
        label: "GSA OF91",
        value: {
          sku: Sku.GSA_V2_PDF,
          productName: TemplateProductName.OF91,
        },
      },
      {
        label: "GSA UCC 1",
        value: {
          sku: Sku.GSA_V2_DOCX,
          productName: TemplateProductName.GSA_UCC1,
        },
      },
      {
        label: "GSA Proof Of Service",
        value: {
          sku: Sku.GSA_V2_DOCX,
          productName: TemplateProductName.PROOF_OF_SERVICE,
        },
      },
      {
        label: "GSA Demand For Closure Of Case",
        value: {
          sku: Sku.GSA_V2_DOCX,
          productName: TemplateProductName.SETTLEMENT_OF_LITIGATION,
        },
      },
    ],
  },
  {
    label: "Non-Disclosure Agreement",
    value: [
      {
        label: "Non-Disclosure Agreement",
        value: {
          sku: Sku.NDA,
        },
      },
    ],
  },
  {
    label: "Test",
    value: [
      {
        label: "Doc 1",
        value: {
          sku: Sku.IRVIC,
          productName: TemplateProductName.DOC_1,
        },
      },
      {
        label: "Doc 2",
        value: {
          sku: Sku.IRVIC,
          productName: TemplateProductName.DOC_2,
        },
      },
      {
        label: "Doc 3",
        value: {
          sku: Sku.IRVIC,
          productName: TemplateProductName.DOC_3,
        },
      },
      {
        label: "Doc 4",
        value: {
          sku: Sku.IRVIC,
          productName: TemplateProductName.DOC_4,
        },
      },
      {
        label: "Doc 5",
        value: {
          sku: Sku.IRVIC,
          productName: TemplateProductName.DOC_5,
        },
      },
    ],
  },
];

export const INSTRUCTIONS = [
  {
    label: "SPC Execution Instruction",
    value: {
      sku: Sku.INSTRUCTIONS,
      productName: InstructionProductName.SPC_EXECUTION,
    },
  },
  {
    label: "SPC Treasury Packet Mailing Instruction",
    value: {
      sku: Sku.INSTRUCTIONS,
      productName: InstructionProductName.TREASURY_PACKET_MAILING,
    },
  },
  {
    label: "SPC Treasury Packet Mailing USPR Instruction",
    value: {
      sku: Sku.INSTRUCTIONS,
      productName: InstructionProductName.TREASURY_PACKET_MAILING_SPC,
    },
  },
  {
    label: "Authentication Instruction",
    value: {
      sku: Sku.INSTRUCTIONS,
      productName: InstructionProductName.AUTHENTICATION,
    },
  },
  {
    label: "Master Bond Instruction",
    value: {
      sku: Sku.INSTRUCTIONS,
      productName: InstructionProductName.MASTER_BOND,
    },
  },
  {
    label: "Tax Settlement Instruction",
    value: {
      sku: Sku.INSTRUCTIONS,
      productName: InstructionProductName.TAX_SETTLEMENT,
    },
  },
  {
    label: "Tax Settlement Instruction V2",
    value: {
      sku: Sku.INSTRUCTIONS,
      productName: InstructionProductName.TAX_SETTLEMENT_V2,
    },
  },
  {
    label: "Trust Execution Instruction",
    value: {
      sku: Sku.INSTRUCTIONS,
      productName: InstructionProductName.TRUST_EXECUTION,
    },
  },
  {
    label: "Protector Replacement Instruction",
    value: {
      sku: Sku.INSTRUCTIONS,
      productName: InstructionProductName.REPLACING_PROTECTOR,
    },
  },
  {
    label: "Trustee Replacement Instruction",
    value: {
      sku: Sku.INSTRUCTIONS,
      productName: InstructionProductName.REPLACING_TRUSTEE,
    },
  },
  {
    label: "Beneficiary Replacement Instruction",
    value: {
      sku: Sku.INSTRUCTIONS,
      productName: InstructionProductName.REPLACING_BENEFICIARY,
    },
  },
  {
    label: "Beneficiary Removal Instruction",
    value: {
      sku: Sku.INSTRUCTIONS,
      productName: InstructionProductName.REMOVING_BENEFICIARY,
    },
  },
  {
    label: "Beneficiary Addition Instruction",
    value: {
      sku: Sku.INSTRUCTIONS,
      productName: InstructionProductName.ADDING_BENEFICIARY,
    },
  },
  {
    label: "Easy Lien Instruction",
    value: {
      sku: Sku.INSTRUCTIONS,
      productName: InstructionProductName.EASY_LIEN,
    },
  },
  {
    label: "GSA Bond Instructions",
    value: {
      sku: Sku.INSTRUCTIONS,
      productName: TemplateProductName.GSA_INSTRUCTION,
    },
  },
];

export const BULK_REGENERATE_PACKETS = [
  {
    label: "SPC Packet",
    value: Sku.SPC,
  },
  {
    label: "SPC Consent Packet",
    value: Sku.SPC_CONSENT,
  },
  {
    label: "SPC Amendment Packet",
    value: Sku.SPC_AMENDMENT,
  },
  {
    label: "Treasury Packet Cover Letter",
    value: Sku.TREASURY_PACKET,
  },
];

export const DASHBOARD_FOLDERS = [
  {
    sku: Sku.SPC,
    folders: [
      { title: "SPC Review", url: "/spc-process", status: SPCStatus.SPC_REVIEW },
      { title: "SPC Signature Review", url: "/spc-process", status: SPCStatus.SPC_SIGNATURE_REVIEW },
      { title: "Authentication Review", url: "/spc-process", status: SPCStatus.AUTHENTICATION_REVIEW },
      {
        title: "Authentication Signature Review",
        url: "/spc-process",
        status: SPCStatus.AUTHENTICATION_SIGNATURE_REVIEW,
      },
    ],
  },
  // {
  //   sku: Sku.SPC_AMENDMENT,
  //   folders: [
  //     { title: "Authentication Review", url: "/spc-process", status: SPCStatus.AUTHENTICATION_REVIEW },
  //     {
  //       title: "Authentication Signature Review",
  //       url: "/spc-process",
  //       status: SPCStatus.AUTHENTICATION_SIGNATURE_REVIEW,
  //     },
  //   ],
  // },
  {
    sku: Sku.TRUST,
    folders: [
      { title: "Trust Review", url: "/trust-process", status: TrustStatus.IN_REVIEW },
      { title: "Trust Signature Review", url: "/trust-process", status: TrustStatus.SIGNATURE_REVIEW },
    ],
  },
  {
    sku: Sku.NDA,
    folders: [{ title: "Non-Disclosure Signature Review", url: "/nda", status: NDAStatus.UPLOADED }],
  },
  {
    sku: Sku.IRVIC,
    folders: [
      { title: "Test Review", url: "/irvic", status: IrvicStatus.DATA_REVIEW },
      { title: "Test Signature Review", url: "/irvic", status: IrvicStatus.DOC_SIGNATURE_REVIEW },
    ],
  },
  {
    sku: Sku.MASTER_BOND,
    folders: [
      {
        title: "Master Bond Review",
        url: "/services/master-bond",
        status: MasterBondProductStatus.DATA_REVIEW,
      },
      {
        title: "Master Bond Signature Review",
        url: "/services/master-bond",
        status: MasterBondProductStatus.DOC1_SIGN_REVIEW,
      },
    ],
  },
  {
    sku: Sku.NNC,
    folders: [
      { title: "NNC Review", url: "/services/nnc", status: NNCStatus.REVIEW },
      { title: "NNC Signature Review", url: "/services/nnc", status: NNCStatus.UPLOADED },
    ],
  },
  // {
  //   sku: Sku.TAX,
  //   folders: [{ title: "Tax Review", url: "/services/tax-settlement", status: TaxProductStatus.REVIEW }],
  // },
  {
    sku: Sku.TAX_V2,
    folders: [
      { title: "Tax Review", url: "/services/tax-settlement", status: TaxProductStatus.REVIEW },
      {
        title: "Tax Signature Review",
        url: "/services/tax-settlement",
        status: TaxProductStatus.DOC_1_SIGNATURE_REVIEW,
      },
      {
        title: "Tax Review 2",
        url: "/services/tax-settlement",
        status: TaxProductStatus.DATA_2_REVIEW,
      },
      {
        title: "Tax Mailing Cover Sheets Review",
        url: "/services/tax-settlement",
        status: TaxProductStatus.DOC_2_SIGNATURE_REVIEW,
      },
    ],
  },
  {
    sku: Sku.GSA_V2,
    folders: [
      { title: "GSA Review", url: "/services/gsa-bond", status: GsaBondProductStatus.DATA_REVIEW },
      {
        title: "GSA Signature Review",
        url: "/services/gsa-bond",
        status: GsaBondProductStatus.DOC1_SIGN_REVIEW,
      },
      {
        title: "GSA Demand For Case Closure Signature Review",
        url: "/services/gsa-bond",
        status: GsaBondProductStatus.DOC3_SIGN_REVIEW,
      },
    ],
  },
  {
    sku: Sku.EASY_LIEN_V2,
    folders: [
      {
        title: "Easy Lien Copyright Review",
        url: "/services/easy-lien",
        status: EasyLienV2ProductStatus.DOC1_REVIEW,
      },
      {
        title: "Easy Lien Copyright Notice Signature Review",
        url: "/services/easy-lien",
        status: EasyLienV2ProductStatus.DOC1_SIGN_REVIEW,
      },
      {
        title: "Easy Lien Copyright Default Signature Review",
        url: "/services/easy-lien",
        status: EasyLienV2ProductStatus.DOC2_SIGN_REVIEW,
      },
      {
        title: "Easy Lien Copyright Lien Signature Review",
        url: "/services/easy-lien",
        status: EasyLienV2ProductStatus.DOC3_SIGN_REVIEW,
      },
    ],
  },
];
