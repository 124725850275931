import { EasyLienV2DocNames, NNCStatus } from "@models/enums";
import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";

import { Subject } from "rxjs";

import { environment } from "src/environments/environment";

import { IPdf, PdfType } from "src/app/models";
import { fileUtil, getScreenHeight } from "src/app/utils";

import { JwtService } from "@providers/services";

@Component({
  selector: "universal-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
})
export class PdfViewerComponent implements OnDestroy {
  private _stop$: Subject<any>;

  pdf: IPdf;
  pdfSrc: any;
  setPDFSrcFn: any;
  pdfBlob: any;

  width: number;
  height: number;

  isPdfLoaded: boolean;

  @Input("pdf")
  set setPdf(pdf: IPdf) {
    this.pdf = pdf;

    const { type, ...rest } = this.pdf;
    this.setPDFSrcFn[type](rest);
  }
  @Output() loadError: EventEmitter<any>;
  @Output() closeViewer: EventEmitter<any>;

  constructor(private _jwt: JwtService) {
    this.setVariables();
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  setVariables(): void {
    this._stop$ = new Subject();
    this.isPdfLoaded = false;

    this.loadError = new EventEmitter();
    this.closeViewer = new EventEmitter();

    this.setPDFSrcFn = {
      [PdfType.SPC]: this._setSPCPdfSrc.bind(this),
      [PdfType.TEMPLATE]: this._setInstructionPdfSrc.bind(this),
      [PdfType.INSTRUCTION]: this._setInstructionPdfSrc.bind(this),
      [PdfType.TRUST]: this._setTrustPdfSrc.bind(this),
      [PdfType.NNC]: this._setNNCPdfSrc.bind(this),
      [PdfType.EASY_LIEN_V2]: this._setEasyLienV2PdfSrc.bind(this),
      [PdfType.GSA_V2]: this._setGSABondV2PdfSrc.bind(this),
      [PdfType.MASTER_BOND]: this._setMasterBondPdfSrc.bind(this),
      [PdfType.TAX_V2]: this._setTAXSettlementPdfSrc.bind(this),
      [PdfType.NDA]: this._setNDAPdfSrc.bind(this),
      [PdfType.IRVIC]: this._setIrvicPdfSrc.bind(this),
    };

    this.height = getScreenHeight() - 210;
    this.width = Math.floor((this.height * 3) / 4) + 120;
  }

  private _setSPCPdfSrc({ sku, docName, userId }): void {
    let url = `${environment.apiURL}/admin/signeddoc?sku=${sku}&userId=${userId}`;

    if (docName) {
      url = `${environment.apiURL}/admin/signeddoc?sku=${sku}&userId=${userId}&docName=${docName}`;
    }

    this.pdfSrc = {
      httpHeaders: { Authorization: this._jwt.getToken() },
      url: url,
    };
  }

  private _setNNCPdfSrc({ sku, userId, productId, productStatus, typeOfParticipant }): void {
    let url = `${environment.apiURL}/admin/signeddoc?sku=${sku}&userId=${userId}&productId=${productId}&typeOfParticipant=${typeOfParticipant}`;
    if (productStatus === NNCStatus.COMPLETED) {
      url = `${environment.apiURL}/admin/user/nncdocs?userId=${userId}&productId=${productId}&typeOfParticipant=${typeOfParticipant}`;
    }
    this.pdfSrc = {
      httpHeaders: { Authorization: this._jwt.getToken() },
      url,
    };
  }

  private _setEasyLienV2PdfSrc({ sku, userId, productId, docName }): void {
    if (docName === EasyLienV2DocNames.FINAL_PACKET) {
      this.pdfSrc = {
        httpHeaders: { Authorization: this._jwt.getToken() },
        url: `${environment.apiURL}/admin/elien2vpack?productId=${productId}&userId=${userId}`,
      };
    } else {
      this.pdfSrc = {
        httpHeaders: { Authorization: this._jwt.getToken() },
        url: `${environment.apiURL}/admin/signeddoc?sku=${sku}&userId=${userId}&productId=${productId}&docName=${docName}`,
      };
    }
  }

  private _setGSABondV2PdfSrc({ sku, userId, productId, docName }): void {
    this.pdfSrc = {
      httpHeaders: { Authorization: this._jwt.getToken() },
      url: `${environment.apiURL}/admin/signeddoc?sku=${sku}&userId=${userId}&productId=${productId}&docName=${docName}`,
    };
  }

  private _setIrvicPdfSrc({ sku, userId, productId, docName }): void {
    this.pdfSrc = {
      httpHeaders: { Authorization: this._jwt.getToken() },
      url: `${environment.apiURL}/admin/signeddoc?sku=${sku}&userId=${userId}&productId=${productId}&docName=${docName}`,
    };
  }

  private _setMasterBondPdfSrc({ sku, userId, productId, docName }): void {
    this.pdfSrc = {
      httpHeaders: { Authorization: this._jwt.getToken() },
      url: `${environment.apiURL}/admin/signeddoc?sku=${sku}&userId=${userId}&productId=${productId}&docName=${docName}`,
    };
  }

  private _setTAXSettlementPdfSrc({ sku, userId, productId, docName }): void {
    this.pdfSrc = {
      httpHeaders: { Authorization: this._jwt.getToken() },
      url: `${environment.apiURL}/admin/signeddoc?sku=${sku}&userId=${userId}&productId=${productId}&docName=${docName}`,
    };
  }

  private _setNDAPdfSrc({ sku, userId }): void {
    this.pdfSrc = {
      httpHeaders: { Authorization: this._jwt.getToken() },
      url: `${environment.apiURL}/admin/signeddoc?userId=${userId}&sku=${sku}`,
    };
  }

  private _setInstructionPdfSrc({ sku, productName = "" }): void {
    let url = `${environment.apiURL}/admin/template?sku=${sku}`;
    if (productName) url += `&productName=${productName}`;

    this.pdfSrc = {
      httpHeaders: { Authorization: this._jwt.getToken() },
      url: url,
    };
  }

  private _setTrustPdfSrc({ trustId, isChangedPdf, changedPdfOffset }): void {
    const url = isChangedPdf
      ? `${environment.apiURL}/admin/doc?trustId=${trustId}&changedoc=${isChangedPdf}&offset=${changedPdfOffset}`
      : `${environment.apiURL}/admin/doc?trustId=${trustId}`;

    this.pdfSrc = { httpHeaders: { Authorization: this._jwt.getToken() }, url };
  }

  onInitPdfView(blob): void {
    this.isPdfLoaded = true;
    this.pdfBlob = blob;
  }

  onErrorPdf(err): void {
    this.isPdfLoaded = false;
    this.loadError.emit(err);
  }

  onClose(): void {
    this.closeViewer.emit();
  }

  onDownloadPdf(): void {
    this.pdfBlob.getData().then((data) => {
      const blob = new Blob([data.buffer], { type: "application/pdf" });
      fileUtil.saveFile(blob, this.pdf.filename);
    });
  }

  onPrintPdf(): void {
    this.pdfBlob.getData().then((data) => {
      const blob = new Blob([data.buffer], { type: "application/pdf" });
      fileUtil.printPdf(blob);
    });
  }
}
