import { Sku, SPCStatus, UserCitizenStatus, TemplateProductName } from "../enums";
import { IGender, IRace, IUserCitizenStatus, ISuffix } from "../models";

export const SPC_REVIEW_STATUSES: SPCStatus[] = [
  SPCStatus.SPC_REVIEW,
  SPCStatus.SPC_SIGNATURE_REVIEW,
  SPCStatus.AUTHENTICATION_REVIEW,
  SPCStatus.AUTHENTICATION_SIGNATURE_REVIEW,
];

export const SPC_2_REVIEW_STATUS = [SPCStatus.DATA_REVIEW, SPCStatus.DOC_SIGNATURE_REVIEW];

export const SPC_DOCS = [
  { value: Sku.SPC, label: "SPC" },
  { value: Sku.SPC_CONSENT, label: "SPC Consent" },
  { value: Sku.SPC_AMENDMENT, label: "SPC Addendum" },
  { value: Sku.SPC_AMENDMENT_2, label: "SPC Addendum 2" },
  { value: Sku.TREASURY_PACKET, label: "Treasury Packet" },
  { value: Sku.MASTER_BOND, label: "Master Bond" },
  { value: Sku.MASTER_BOND_COVER_LETTER, label: "Master Bond Cover Letter" },
  { value: `${Sku.SPC}|${TemplateProductName.GSA_UCC1}`, label: "SPC UCC-1" },
  { value: `${Sku.SPC_AMENDMENT}|${TemplateProductName.GSA_UCC1}`, label: "SPC UCC-3" },
  { value: `${Sku.SPC_AMENDMENT_2}|${TemplateProductName.GSA_UCC1}`, label: "SPC2 UCC-3" },
  // { value: Sku.TAX, label: 'Tax' },
  // { value: Sku.GSA, label: 'GSA Bond' },
];

export const SPC_EMAIL_TYPES = [
  {
    value: "SPCRequired",
    label: "SPC Required",
  },
  {
    value: "SPCRejected",
    label: "SPC Rejected",
  },
  {
    value: "SPCSignatureRequired",
    label: "SPC Signature Required",
  },
  {
    value: "SPCSubmitted",
    label: "SPC Submitted to UCC",
  },
  {
    value: "SPCConfirmed",
    label: "SPC Confirmed",
  },
  {
    value: "SPCConfirmedPremanentResident",
    label: "SPC Confirmed Permanent Resident",
  },
  {
    value: "SPCAmendmentSignatureRequired",
    label: "SPC Addendum Signature Required",
  },
  {
    value: "SPCAmendmentRejected",
    label: "SPC Addendum Rejected",
  },
  {
    value: "SPCAmendmentAuthenticationReview",
    label: "SPC Addendum Authentication Review",
  },
  {
    value: "SPCAmendmentSubmittedToUCC",
    label: "SPC Addendum Submitted To UCC",
  },
  {
    value: "SPCAmendmentConfirmed",
    label: "SPC Addendum Confirmed",
  },
  {
    value: "SPCAmendmentSecured",
    label: "SPC Addendum Secured",
  },
  {
    value: "Meta1Intake",
    label: "Meta1 Intake",
  },
  {
    value: "Meta1Processed",
    label: "Meta1 Processed",
  },
];

export const TARGET_SPC_STATUS = {
  approve: {
    [SPCStatus.SPC_REVIEW]: SPCStatus.SPC_SIGNATURE_REQUIRED,
    [SPCStatus.SPC_SIGNATURE_REVIEW]: SPCStatus.SPC_SUBMITTED_TO_UCC,
    [SPCStatus.AUTHENTICATION_REVIEW]: SPCStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
    [SPCStatus.AUTHENTICATION_SIGNATURE_REVIEW]: SPCStatus.UCC3_SUBMITTED_TO_UCC,
  },
  reject: {
    [SPCStatus.SPC_REVIEW]: SPCStatus.SPC_REQUIRED,
    [SPCStatus.SPC_SIGNATURE_REVIEW]: SPCStatus.SPC_SIGNATURE_REQUIRED,
    [SPCStatus.AUTHENTICATION_SIGNATURE_REVIEW]: SPCStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
    [SPCStatus.AUTHENTICATION_REVIEW]: SPCStatus.VERIFIED,
  },
};

export const TARGET_SPC_STATUS_2 = {
  approve: {
    [SPCStatus.DATA_REVIEW]: SPCStatus.SPC_AMENDMENT_2_APPROVE,
    [SPCStatus.DOC_SIGNATURE_REVIEW]: SPCStatus.SPC_AMENDMENT_2_SIGNATURE_APPROVE,
  },
  reject: {
    [SPCStatus.DATA_REVIEW]: SPCStatus.SPC_AMENDMENT_2_REJECTED,
    [SPCStatus.DOC_SIGNATURE_REVIEW]: SPCStatus.SPC_AMENDMENT_2_SIGNATURE_REJECTED,
  },
};

export const CITIZEN_STATUSES: IUserCitizenStatus[] = [
  {
    userCitizenStatus: UserCitizenStatus.US_CITIZEN,
    ProductType: "SPC",
    ProductName: "USC",
    SPCRequired: true,
  },
  {
    userCitizenStatus: UserCitizenStatus.US_PERMANENT_RESIDENT,
    ProductType: "SPC",
    ProductName: "USPR",
    SPCRequired: true,
  },
  {
    userCitizenStatus: UserCitizenStatus.NATURALIZED_US_CITIZEN,
    ProductType: "SPC",
    ProductName: "NUSC",
    SPCRequired: true,
  },
  {
    userCitizenStatus: UserCitizenStatus.NA,
    ProductType: "SPC",
    ProductName: "None of the Above",
    SPCRequired: false,
  },
];

export const USER_SUFFIX_ARR: ISuffix[] = [
  {
    title: "None",
    value: "None",
  },
  {
    title: "Sr",
    value: "Sr",
  },
  {
    title: "Jr",
    value: "Jr",
  },
  {
    title: "I",
    value: "I",
  },
  {
    title: "II",
    value: "II",
  },
  {
    title: "III",
    value: "III",
  },
  {
    title: "IV",
    value: "IV",
  },
  {
    title: "V",
    value: "V",
  },
  {
    title: "VI",
    value: "VI",
  },
  {
    title: "VII",
    value: "VII",
  },
  {
    title: "VIII",
    value: "VIII",
  },
  {
    title: "IX",
    value: "IX",
  },
  {
    title: "X",
    value: "X",
  },
];

export const RACES: IRace[] = [
  { key: "White", value: "White" },
  { key: "Black", value: "Black" },
  { key: "Asian", value: "Asian" },
  { key: "Hispanic", value: "Hispanic" },
  { key: "Native American", value: "Native American" },
  { key: "Pacific Islander", value: "Pacific Islander" },
  { key: "Native Hawaiian", value: "Native Hawaiian" },
];

export const GENDER: IGender[] = [
  { title: "Man", value: "man" },
  { title: "Woman", value: "woman" },
];

export const BASIC_PROFILE_FORMAT = {
  userFirstName: /^[a-zA-Z]+$/,
  userMiddleName: /^[a-zA-Z]+$/,
  userLastName: /^[a-zA-Z]+$/,
  userAddressLine1: /^[a-zA-Z0-9 ]+$/,
  userCountry: /^[a-zA-Z]+$/,
  userCity: /^[a-zA-Z0-9 ]+$/,
  userState: /^[a-zA-Z0-9 ]+$/,
};

export const BASIC_PROFILE_FIELDS = {
  userFirstName: "userFirstName",
  userMiddleName: "userMiddleName",
  userLastName: "userLastName",
  userEMail: "userEMail",
  userPhone: "userPhone",
  userCountry: "userCountry",
  userState: "userState",
  userCity: "userCity",
  userAddressLine1: "userAddressLine1",
  userPostalCode: "userPostalCode",
  userCitizenStatus: "userCitizenStatus",
  userBirthCountry: "userBirthCountry",
  under18: "isUnder18",
  nameSuffix: "nameSuffix",
  bcGender: "bcGender",
  userEthnicity: "userEthnicity",
  userCounty: "userCounty",
};

export const EXTENDED_PROFILE_FIELDS = {
  [UserCitizenStatus.US_CITIZEN]: [
    "bcFirstName",
    "bcMiddleName",
    "bcLastName",
    "nameSuffix",
    "bcTitle",
    "bcCounty",
    "bcState",
    "bcFileNumber",
    // "userCounty",
    "bcDOB",
    // "bcGender",
    "sscSSN",
    "sscBondNumber",
    "driversLicenseNumber",
    "passportNumber",
    "registeredMailNumber",
    "isLegalNameDiff",
    "marriageState",
    "driversLicenseState",
    "marriageLicNum",
    "marriageSpouseStrawman",
    "marriageDate",
    "marriageFirstName",
    "marriageMiddleName",
    "marriageLastName",
    "spcNotaryState",
    "spcNotaryCounty",
    // "userEthnicity",
  ],
  [UserCitizenStatus.US_PERMANENT_RESIDENT]: [
    "bcFirstName",
    "bcMiddleName",
    "bcLastName",
    "nameSuffix",
    "bcState",
    "bcFileNumber",
    // "userCounty",
    "bcDOB",
    // "bcGender",
    "dateEnteredUS",
    "sscSSN",
    "sscBondNumber",
    "driversLicenseNumber",
    "registeredMailNumber",
    "isLegalNameDiff",
    "marriageState",
    "driversLicenseState",
    "marriageLicNum",
    "marriageSpouseStrawman",
    "marriageDate",
    "marriageFirstName",
    "marriageMiddleName",
    "marriageLastName",
    "spcNotaryState",
    "spcNotaryCounty",
    // "userEthnicity",
  ],
  [UserCitizenStatus.NATURALIZED_US_CITIZEN]: [
    "bcFirstName",
    "bcMiddleName",
    "bcLastName",
    "nameSuffix",
    "bcState",
    "bcFileNumber",
    // "userCounty",
    "bcDOB",
    // "bcGender",
    "dateEnteredUS",
    "sscSSN",
    "sscBondNumber",
    "driversLicenseNumber",
    "passportNumber",
    "registeredMailNumber",
    "isLegalNameDiff",
    "marriageState",
    "driversLicenseState",
    "marriageLicNum",
    "marriageSpouseStrawman",
    "marriageDate",
    "marriageFirstName",
    "marriageMiddleName",
    "marriageLastName",
    "spcNotaryState",
    "spcNotaryCounty",
    // "userEthnicity",
  ],
};

export const SPC_AMEND_FIELDS = {
  basic: {
    authenticationNumber: "authNum",
    registeredMailNumber: "registeredMailNum",
  },
  otherUCC: {
    otherUCCState: "uccState",
    otherUCCStateFileNumber: "uccStateFileNum",
  },
  child: {
    childFirstName: "firstName",
    childMiddleName: "middleName",
    childLastName: "lastName",
    birthState: "birthState",
    countyOfBirth: "birthCounty",
    birthDate: "birthDate",
    birthCertificateTitle: "birthCertTitle",
    birthCertificateNumber: "birthCertNum",
    birthCertificateAuthenticationNumber: "birthCertAuthNum",
  },
};

export const SPC_PRODUCT_NAMES = ["USC", "USPR"];

export const SPC_PRODUCT_FIELDS = ["sku", "filingNumber", "envelopeId", "productName"];

export const SPC_AMEND_PRODUCT_FIELDS = ["sku", "filingNumber", "envelopeId"];

export const SPC_UPDATE_FIELDS = [
  "id",
  "userFirstName",
  "userMiddleName",
  "userLastName",
  "userPhone",
  "userEMail",
  "userCountry",
  "userBirthCountry",
  "userState",
  "userCity",
  "userAddressLine1",
  "userPostalCode",
  "userCitizenStatus",
  "nameSuffix",
  "bcFirstName",
  "bcMiddleName",
  "bcLastName",
  "bcGender",
  "bcTitle",
  "bcState",
  "bcFileNumber",
  "bcDOB",
  "dateEnteredUS",
  "sscSSN",
  "sscBondNumber",
  "driversLicenseNumber",
  "passportNumber",
  "registeredMailNumber",
  "spcNotaryCounty",
  "marriageState",
  "driversLicenseState",
  "marriageLicNum",
  "marriageSpouseStrawman",
  "marriageDate",
  "spcNotaryState",
  "bcLastName",
  "bcCounty",
  "userEthnicity",
  "marriageFirstName",
  "marriageMiddleName",
  "marriageLastName",
  "userCounty",
  "isLegalNameDiff",
  "status.email",
  "status.status",
  "status.type",
];

export const SPC_PRODUCT_UPDATE_FIELDS = {
  [Sku.SPC]: ["sku", "filingNumber", "envelopeId", "productName"],
  [Sku.SPC_CONSENT]: ["sku"],
  [Sku.SPC_AMENDMENT]: {
    sku: "sku",
    filingNumber: "filingNumber",
    envelopeId: "envelopeId",
    authenticationNumber: "authenticationNumber",
    registeredMailNumber: "registeredMailNumber",
  },
  [Sku.SPC_AMENDMENT_2]: {
    sku: "sku",
    filingNumber: "filingNumber",
    envelopeId: "envelopeId",
    authenticationNumber: "authenticationNumber",
    registeredMailNumber: "registeredMailNumber",
  },
  child: [
    "childFirstName",
    "childMiddleName",
    "childLastName",
    "birthState",
    "countyOfBirth",
    "birthDate",
    "birthCertificateTitle",
    "birthCertificateNumber",
    "birthCertificateAuthenticationNumber",
  ],
  ucc: ["otherUCCState", "otherUCCStateFileNumber"],
};

export const HUMAN_FIELDS = {
  userFirstName: "firstName",
  userMiddleName: "middleName",
  userLastName: "lastName",
  userEMail: "email",
  userCountry: "country",
  userState: "state",
  userCity: "city",
  userAddressLine1: "address",
};
