import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgxDocViewerModule } from "ngx-doc-viewer";

import { DocViewerComponent } from "./doc-viewer.component";

@NgModule({
  declarations: [DocViewerComponent],
  imports: [CommonModule, NgxDocViewerModule],
  exports: [DocViewerComponent],
})
export class DocViewerModule {}
