import { Injectable } from "@angular/core";
import { CanActivate, UrlTree, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { Observable } from "rxjs";

import { JwtService } from "../services";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private _router: Router, private _jwt: JwtService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this._jwt.getToken();

    if (token) {
      return true;
    }

    this._router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
