import { HttpParams } from "@angular/common/http";

export interface QueryString {
  [k: string]: string | number | boolean;
}

export const getHttpParams: (obj: any) => HttpParams = (obj) => {
  const params = new HttpParams();
  Object.keys(obj).forEach((key) => params.append(key, obj[key]));
  return params;
};

export const getHttpQueryString: (obj: QueryString) => string = (obj) => {
  const queryString = Object.entries(obj)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
  return queryString;
};
