import { MatDialogConfig } from "@angular/material/dialog";
/**
 * Return Angular Material dialog config
 *
 * @param {string} width Width of dialog
 * @param {boolean} autoFocus default value is true
 * @param {boolean} disableClose default value is true
 * @param {boolean} hasBackdrop default value is true
 * @param {object} position Position of dialog: top, right, bottom and left
 * @param {object} data Data of dialog which will be passed to dialog component
 * @param {string} panelClass CSS class of dialog overlay which is used for customizing dialog style
 * @param {string} backdropClass
 * @return Return the config of dialog
 */
export const getDialogConfig = ({
  width,
  autoFocus = true,
  disableClose = true,
  hasBackdrop = true,
  position = {},
  data = {},
  panelClass = "",
  backdropClass = "",
}): MatDialogConfig => {
  const dialogConfig = new MatDialogConfig();
  Object.assign(dialogConfig, {
    width,
    autoFocus,
    disableClose,
    hasBackdrop,
    position: { ...position, top: "80px" },
    data,
    panelClass,
    minWidth: 480,
    backdropClass,
    closeOnNavigation: true,
  });

  return dialogConfig;
};
