import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd } from "@angular/router";

import { Subject } from "rxjs";
import { filter, map, takeUntil, tap } from "rxjs/operators";

import { NO_CB } from "@models/constants";
import { getPageTitle } from "@utils/title";

import { UserService } from "./providers/services";

@Component({
  selector: "app-my-app",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  private _stop$: Subject<any>;

  constructor(private _router: Router, private _userService: UserService, private _title: Title) {
    this.setVariables();
  }

  ngOnInit() {
    this.setPageTitle();
    this._userService.populate();

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._stop$),
      )
      .subscribe(() => {
        // eslint-disable-next-line no-undef
        const body = document.getElementsByTagName("body")[0];
        // eslint-disable-next-line no-undef
        const modalBackdrop = document.getElementsByClassName("modal-backdrop")[0];
        if (body.classList.contains("modal-open")) {
          body.classList.remove("modal-open");
          modalBackdrop.remove();
        }
      });
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  setVariables(): void {
    this._stop$ = new Subject();
  }

  setPageTitle(): void {
    this._router.events
      .pipe(
        filter((ev) => ev instanceof NavigationEnd),
        map((ev: any) => getPageTitle(ev.url)),
        tap((title) => this._title.setTitle(title)),
      )
      .subscribe(NO_CB);
  }
}
