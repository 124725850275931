import { Component, OnInit, Renderer2, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";

import { combineLatest, Subject } from "rxjs";
import { filter, takeUntil, tap } from "rxjs/operators";

import sum from "lodash/sum";

import {
  SPCStatus,
  TrustStatus,
  TaxProductStatus,
  GsaBondStatus,
  EasyLienProductStatus,
  Sku,
} from "@models/enums";
import { DASHBOARD_FOLDERS, NO_CB } from "@models/constants";

import { StoreService, UserService } from "@providers/services";

import { getFolderCountByStatus } from "@utils/common";
import { ROUTES } from "../sidebar/sidebar.component";

const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

declare let $: any;

@Component({
  selector: "app-navbar-cmp",
  templateUrl: "navbar.component.html",
})
export class NavbarComponent implements OnInit, OnDestroy {
  dashboardFolders: any;
  dashboardReviewCounts: any;

  private listTitles: any[];
  private nativeElement: Node;
  private toggleButton: any;
  private sidebarVisible: boolean;
  private _stop$: Subject<any>;

  location: Location;
  mobile_menu_visible: any = 0;

  user: any;
  nncSignReviewCnt: number;

  @ViewChild("app-navbar-cmp", { static: false }) button: any;

  constructor(
    location: Location,
    private _renderer: Renderer2,
    private _element: ElementRef,
    private _router: Router,
    private _store: StoreService,
    private _userService: UserService,
  ) {
    this.location = location;
    this.nativeElement = _element.nativeElement;
    this.sidebarVisible = false;

    this._stop$ = new Subject();
    this.dashboardFolders = DASHBOARD_FOLDERS;
    this.dashboardReviewCounts = [];
  }

  ngOnInit() {
    this.getProcessReviewCnt();
    this.listTitles = ROUTES.filter((listTitle) => listTitle);

    const navbar: HTMLElement = this._element.nativeElement;
    const body = document.getElementsByTagName("body")[0];
    // eslint-disable-next-line prefer-destructuring
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    if (body.classList.contains("sidebar-mini")) {
      misc.sidebar_mini_active = true;
    }
    if (body.classList.contains("hide-sidebar")) {
      misc.hide_sidebar_active = true;
    }
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._stop$),
      )
      .subscribe(() => {
        this.sidebarClose();

        const $layer = document.getElementsByClassName("close-layer")[0];
        if ($layer) {
          $layer.remove();
        }
      });

    this._userService.user
      .pipe(
        filter(Boolean),
        tap((user) => (this.user = user)),
        takeUntil(this._stop$),
      )
      .subscribe(NO_CB);
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  getProcessReviewCnt(): void {
    combineLatest([
      this._store.dashboardReviewCount.pipe(tap((count) => (this.dashboardReviewCounts = count))),
    ])
      .pipe(takeUntil(this._stop$))
      .subscribe(NO_CB);
  }

  getFolderCount(sku, status): number {
    const newSku = [SPCStatus.AUTHENTICATION_REVIEW, SPCStatus.AUTHENTICATION_SIGNATURE_REVIEW].includes(
      status,
    )
      ? Sku.SPC_AMENDMENT
      : sku;
    return getFolderCountByStatus(newSku, status, this.dashboardReviewCounts);
  }

  onCardClick(ev, url, status): void {
    ev.preventDefault();
    this._router.navigateByUrl(`${url}?status=${status}`);
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
    } else {
      setTimeout(() => {
        body.classList.add("sidebar-mini");

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(() => {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(() => {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  hideSidebar() {
    const body = document.getElementsByTagName("body")[0];
    const sidebar = document.getElementsByClassName("sidebar")[0];

    if (misc.hide_sidebar_active === true) {
      setTimeout(() => {
        body.classList.remove("hide-sidebar");
        misc.hide_sidebar_active = false;
      }, 300);
      setTimeout(() => {
        sidebar.classList.remove("animation");
      }, 600);
      sidebar.classList.add("animation");
    } else {
      setTimeout(() => {
        body.classList.add("hide-sidebar");
        // $('.sidebar').addClass('animation');
        misc.hide_sidebar_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(() => {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(() => {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  onResize() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  sidebarOpen() {
    const $toggle = document.getElementsByClassName("navbar-toggler")[0];
    const { toggleButton } = this;
    const body = document.getElementsByTagName("body")[0];
    setTimeout(() => {
      toggleButton.classList.add("toggled");
    }, 500);
    body.classList.add("nav-open");
    setTimeout(() => {
      $toggle.classList.add("toggled");
    }, 430);

    const $layer = document.createElement("div");
    $layer.setAttribute("class", "close-layer");

    if (body.querySelectorAll(".main-panel")) {
      document.getElementsByClassName("main-panel")[0].appendChild($layer);
    } else if (body.classList.contains("off-canvas-sidebar")) {
      document.getElementsByClassName("wrapper-full-page")[0].appendChild($layer);
    }

    setTimeout(() => {
      $layer.classList.add("visible");
    }, 100);

    // eslint-disable-next-line func-names
    $layer.onclick = function () {
      // assign a function
      body.classList.remove("nav-open");
      this.mobile_menu_visible = 0;
      this.sidebarVisible = false;

      $layer.classList.remove("visible");
      setTimeout(() => {
        $layer.remove();
        $toggle.classList.remove("toggled");
      }, 400);
    }.bind(this);

    body.classList.add("nav-open");
    this.mobile_menu_visible = 1;
    this.sidebarVisible = true;
  }

  sidebarClose() {
    const $toggle = document.getElementsByClassName("navbar-toggler")[0];
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton.classList.remove("toggled");
    const $layer = document.createElement("div");
    $layer.setAttribute("class", "close-layer");

    this.sidebarVisible = false;
    body.classList.remove("nav-open");
    // $('html').removeClass('nav-open');
    body.classList.remove("nav-open");
    if ($layer) {
      $layer.remove();
    }

    setTimeout(() => {
      $toggle.classList.remove("toggled");
    }, 400);

    this.mobile_menu_visible = 0;
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  onShowSpcReviews(ev): void {
    ev.preventDefault();
    this._router.navigate(["/spc-process"], {
      queryParams: { status: SPCStatus.SPC_REVIEW },
    });
  }

  onShowSpcSignReviews(ev): void {
    ev.preventDefault();
    this._router.navigate(["/spc-process"], {
      queryParams: { status: SPCStatus.SPC_SIGNATURE_REVIEW },
    });
  }

  onShowSpcAuthReviews(ev): void {
    ev.preventDefault();
    this._router.navigate(["/spc-process"], {
      queryParams: { status: SPCStatus.AUTHENTICATION_REVIEW },
    });
  }

  onShowTrustReviews(ev): void {
    ev.preventDefault();
    this._router.navigate(["/trust-process"], {
      queryParams: { status: TrustStatus.IN_REVIEW },
    });
  }

  onShowTrustSignReviews(ev: any): void {
    ev.preventDefault();
    this._router.navigate(["/trust-process"], {
      queryParams: { status: TrustStatus.SIGNATURE_REVIEW },
    });
  }

  onShowTaxReviews(ev: any): void {
    ev.preventDefault();
    this._router.navigate(["/services/tax-settlement"], {
      queryParams: { status: TaxProductStatus.REVIEW },
    });
  }

  onShowGsaReviews(ev: any): void {
    ev.preventDefault();
    this._router.navigate(["/services/gsa-bond"], {
      queryParams: { status: GsaBondStatus.REVIEW },
    });
  }

  onShowEasyLienReviews(ev: any): void {
    ev.preventDefault();
    this._router.navigate(["/services/easy-lien"], {
      queryParams: { status: EasyLienProductStatus.EXEMPTION_REVIEW },
    });
  }

  onLogout(ev): void {
    ev.preventDefault();
    this._userService.removeUser();
    this._router.navigateByUrl("/login");
  }

  get totalReviewsCnt(): number {
    return sum(this.dashboardReviewCounts.map((folder) => folder.count || 0));
  }
}
