import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MaterialModule } from "src/app/shared";

import { HumanComponent } from "./human.component";
import { SearchHumanComponent } from "./search-human/search-human.component";

@NgModule({
  declarations: [HumanComponent, SearchHumanComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  exports: [HumanComponent, SearchHumanComponent],
})
export class HumanModule {}
