export enum SPCStatus {
  INVITED = "Invited",
  NEW_USER = "New User",
  SPC_REQUIRED = "SPC Required",
  SPC_REVIEW = "SPC Review",
  SPC_SIGNATURE_REQUIRED = "SPC Signature Required",
  SPC_AMENDMENT_2_REJECTED = "SpcAmendment2 Rejected",
  SPC_AMENDMENT_2_APPROVE = "SpcAmendment2 Approve",
  SPC_AMENDMENT_2_SIGNATURE_REJECTED = "SpcAmendment2 signature Rejected",
  SPC_AMENDMENT_2_SIGNATURE_APPROVE = "SpcAmendment2 signature Approve",
  SPC_SIGNATURE_REVIEW = "SPC Signature Review",
  SPC_SUBMITTED_TO_UCC = "SPC Submitted to UCC",
  VERIFIED = "Verified",
  AUTHENTICATION_REVIEW = "Authentication Review",
  AUTHENTICATION_SIGNATURE_REQUIRED = "Authentication Signature Required",
  AUTHENTICATION_SIGNATURE_REVIEW = "Authentication Signature Review",
  UCC3_SUBMITTED_TO_UCC = "UCC 3 submitted to UCC",
  AUTHENTICATION_COMPLETED = "Authentication Completed",
  TREASURY_PACKET_SENT = "Treasury Packet sent",
  SECURED = "Secured",
  DELETED = "Deleted",
  DATA_REVIEW = "Data review",
  DATA_REJECT = "Data reject",
  DOC_READY = "Doc ready",
  DOC_SIGNATURE_REVIEW = "Doc signature review",
  ACCEPTED = "Accepted",
  COMPLETED = "Completed",
}

export enum UserCitizenStatus {
  US_CITIZEN = "U.S. Citizen",
  US_PERMANENT_RESIDENT = "U.S. Permanent Resident",
  NATURALIZED_US_CITIZEN = "Naturalized U.S. Citizen",
  NA = "None of the Above",
}

export enum Sku {
  SPC = "spc",
  SPC_CONSENT = "spcConsent",
  SPC_AMENDMENT = "spcAmendment",
  SPC_AMENDMENT_2 = "spcAmendment2",
  SPC_AMENDMENT_OLD = "spcAmendmentOld",
  TREASURY_PACKET = "USTCoverLetter",
  MASTER_BOND = "masterBond",
  MASTER_BOND_COVER_LETTER = "masterBondCoverLetter",
  TAX = "tax",
  TAX_V2 = "tax2v",
  TAX_V2_DOCX = "tax2vDocx",
  TAX_V2_PDF = "tax2vPdf",
  GSA = "gsa",
  GSA_DOCX = "gsaDocx",
  GSA_PDF = "gsaPdf",
  GSA_V2_DOCX = "gsa2vDocx",
  GSA_V2_PDF = "gsa2vPdf",
  EASY_LIEN = "elien",
  EASY_LIEN_V2 = "elien2v",
  GSA_V2 = "gsa2v",
  TRUST = "trust",
  BENEFICIARY_TRUST = "trustbenef",
  CHANGE_AMENDMENT = "changeAmendment",
  INSTRUCTIONS = "InstructionsPDF",
  TRUST_WALLET = "trustWallet",
  TRUST_PROPERTY = "trustproperty",
  NNC = "nnc",
  NNC_PDF = "nncPdf",
  NDA = "nda",
  IRVIC = "irvic",
}

export enum TemplateProductName {
  SPC_USC = "USC",
  SPC_USPR = "USPR",
  SPC_USC_TRUE = "USC-true",
  SPC_USPR_TRUE = "USPR-true",
  SPC_AMENDMENT_DEFAULT = "default",
  LEGAL_NOTICE_DEMAND = "legalNoDe",
  MASTER_BOND = "masterBond",
  MASTER_BOND_P = "masterBondP",
  TAX_SETTLEMENT = "taxSettle",
  BOND_SETTLEMENT = "bondSettle",
  TAX_BILL_OF_EXCHANGE = "taxBillofEx",
  GSA_INSTRUCTION = "gsaBondInstructions",
  GSA_COVER_LETTER = "gsaCoverLetter",
  GSA_JUDICIAL_REVIEW = "gsaJudicialReview",
  GSA_DECLARATION_OF_FACTS = "gsaDeclarationOfFacts",
  GSA_FIDUCIARY_CONTRACT_DISTRICT_COURT = "gsaFiduciaryContractDistrictCourt",
  GSA_FIDUCIARY_CONTRACT_IRS = "gsaFiduciaryContractIrs",
  GSA_FIDUCIARY_CONTRACT_DTC = "gsaFiduciaryContractDtc",
  GSA_FIDUCIARY_CONTRACT_TREASURY = "gsaFiduciaryContractTreasury",
  W9_COVER_LETTER = "w9CoverLetter",
  APPENDIX1 = "appendix1",
  APPENDIX2 = "appendix2",
  APPENDIX3 = "appendix3",
  F56 = "f56",
  W9 = "w9",
  F1040V = "f1040V",
  SF24 = "sf24",
  SF25 = "sf25",
  SF25A = "sf25A",
  SF28 = "sf28",
  OF90 = "of90",
  OF91 = "of91",
  AFFIDAVIT_OF_OWNERSHIP = "affidavitofOwnership",
  GSA_UCC1 = "uccFilling",
  PROOF_OF_SERVICE = "proofOfService",
  SETTLEMENT_OF_LITIGATION = "settlementOfLitigation",
  NOTICE_EXEMPTION = "noExemption",
  NOTICE_EXEMPTION_OFFENSE = "noExemptionVac",
  NOTICE_EXEMPTION_FLEXIBLE = "flexNot",
  NOTICE_DEFAULT = "noDefault",
  NOTICE_LIEN = "noLien",
  LIEN_FILLING = "lienFilling",
  PROTECTOR_AMENDMENT = "PCamendment",
  TRUSTEE_AMENDMENT = "TSamendment",
  BENEFICIARY_APPOINT = "BFappoint",
  BENEFICIARY_REMOVAL = "BFremoval",
  BENEFICIARY_AMEND = "BFamendment",
  TRUST_ADD_PROPERTY = "addendum",
  TRUST_PROPERTY_FILLING = "filling",
  CERT_OF_MAILINGS = "certOfMailings",
  NEW_TAX = "newTax",
  NEW_TAX_2 = "newTax2",
  TAX_SETTLEMENT_BOND = "taxSettlementBond",
  TAX_BILL_EXCHANGE = "taxBillOfExchange",
  TAX_PRIVATE_INDEMNITY_BOND = "taxPrivateIndemnityBond",
  TAX_PRIVATE_BILL_OF_EXCHANGE = "taxPrivateBillOfExchange",
  MASTER_TAX_UCC = "masterTaxUcc",
  BOE_TREASURY_COVER_LETTER = "boeTreasuryCoverLetter",
  PIB_TREASURY_COVER_LETTER = "boeTreasuryCoverLetter",
  BOE_ATTACHMENT_A = "boeAttachmentA",
  BOE_ATTACHMENT_B = "boeAttachmentB",
  PIB_ATTACHMENT_A = "pibAttachmentA",
  PIB_ATTACHMENT_B = "pibAttachmentB",
  F1040ES = "f1040es",
  F1040v = "f1040v",
  NNC_JUDGE = "judge",
  NNC_CLERK = "clerk",
  NNC_EXHIBIT_A = "exhibitA",
  NNC_EXHIBIT_B = "exhibitB",
  ELIENV2_COPYRIGHT = "noticeOfCopyrightNotice",
  ELIENV2_DEFAULT = "noticeOfDefault",
  ELIENV2_LIEN = "noticeOfLien",
  ELIENV2_FILLING = "lienFilling",
  ELIENV2_EXHIBIT_A = "exhibitA",
  ELIENV2_EXHIBIT_B = "exhibitB",
  ELIENV2_EXHIBIT_C = "exhibitC",
  ELIENV2_EXHIBIT_D = "exhibitD",
  ELIENV2_EXHIBIT_E = "exhibitE",
  ELIENV2_EXHIBIT_F = "exhibitF",
  DOC_1 = "doc1",
  DOC_2 = "doc2",
  DOC_3 = "doc3",
  DOC_4 = "doc4",
  DOC_5 = "doc5",
}

export enum InstructionProductName {
  SPC_EXECUTION = "SPCExecution",
  TREASURY_PACKET_MAILING = "treasuryPacketMailing",
  TREASURY_PACKET_MAILING_SPC = "treasuryPacketMailingUSPR",
  AUTHENTICATION = "authentificationInstructions",
  MASTER_BOND = "masterBond",
  TAX_SETTLEMENT = "taxSettlement",
  TAX_SETTLEMENT_V2 = "tax2vInstructions",
  TRUST_EXECUTION = "trustExecution",
  REPLACING_PROTECTOR = "ReplacingProtector",
  REPLACING_TRUSTEE = "ReplacingTrustee",
  REPLACING_BENEFICIARY = "ReplacingBeneficiary",
  REMOVING_BENEFICIARY = "RemovingBeneficiary",
  ADDING_BENEFICIARY = "AddingBeneficiary",
  EASY_LIEN = "easyLien",
}
