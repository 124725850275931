import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { UserRoleDirective } from "./user-role.directive";
import { CurrencyFormatterDirective } from "./currency-formatter.directive";
import { PhoneMaskDirective } from "./phone-mask.directive";
import { UserRoleSubRouteDirective } from "./user-role-sub-route.directive";

@NgModule({
  exports: [UserRoleDirective, CurrencyFormatterDirective, PhoneMaskDirective, UserRoleSubRouteDirective],
  declarations: [
    UserRoleDirective,
    CurrencyFormatterDirective,
    PhoneMaskDirective,
    UserRoleSubRouteDirective,
  ],
  imports: [CommonModule],
})
export class DirectivesModule {}
