import { MasterBondDocNames, MasterBondProductStatus, NDAStatus, Sku, TemplateProductName } from "../enums";

export const NDA_DOCS = [
  {
    label: "Non-Disclosure Agreement",
    Sku: Sku.NDA,
  },
];

export const NDA_APPROVAL_STATUS = [NDAStatus.UPLOADED];
