import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "./api.service";
import { JwtService } from "./jwt.service";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private _user$: BehaviorSubject<any>;
  user: Observable<any>;

  constructor(private _api: ApiService, private _jwt: JwtService, private _session: SessionService) {
    this._setVariables();
  }

  private _setVariables(): void {
    this._user$ = new BehaviorSubject(null);
    this.user = this._user$.asObservable();
  }

  setUser(res): void {
    const token = res.headers.get("authorization");
    if (token) this._jwt.setToken(token);

    this._user$.next(res.body);
    this._session.setSession("@universal/user", res.body);
  }

  removeUser(): void {
    this._jwt.removeToken();
    this._user$.next(null);
  }

  populate(): void {
    const token = this._jwt.getToken();
    if (!token) {
      this.removeUser();
      return;
    }

    this._api.get("/admin").subscribe(
      (res) => this.setUser(res),
      () => this.removeUser(),
    );
  }

  login({ email, password }): Observable<any> {
    return this._api.post(`/admin/login`, { email, password }).pipe(map(this.setUser.bind(this)));
  }

  sendResetPasswordEmail(email: string): Observable<any> {
    return this._api.get(`/admin/reset_password?email=${email}`);
  }

  resetPassword({ password, token }): Observable<any> {
    return this._api.post(`/admin/reset_password`, { password, token });
  }

  generateUserToken(userId: any): Observable<any> {
    return this._api.get(`/admin/user/token?userId=${userId}`).pipe(map((res) => res.body));
  }

  unbindBoe(productId: any, userId: any) {
    return this._api
      .post(`/admin/user/unbindboe`, { productId, userId })
      .pipe(map((res) => res.body));
  }

  bindBoe(productId: any, userId: any) {
    return this._api
      .post(`/admin/user/bindboe`, { productId, userId })
      .pipe(map((res) => res.body));
  }
}
