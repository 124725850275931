export enum NavItemType {
  Sidebar = 1, // Only ever shown on sidebar
  NavbarLeft = 2, // Left-aligned icon-only link on navbar in desktop mode, shown above sidebar items on collapsed sidebar in mobile mode
  NavbarRight = 3, // Right-aligned link on navbar in desktop mode, shown above sidebar items on collapsed sidebar in mobile mode
}

export enum Status {
  SUCCESS = "success",
  FAILURE = "failure",
  DECLINE = "decline",
}

export enum PdfType {
  INSTRUCTION = "instruction",
  SPC = "spc",
  TRUST = "trust",
  TEMPLATE = "template",
  NNC = "nnc",
  NNC_PACKET = "nnc packet",
  EASY_LIEN_V2 = "easy lien",
  GSA_V2 = "gsa2v",
  MASTER_BOND = "master bond",
  TAX = "tax",
  TAX_V2 = "tax2v",
  NDA = "nda",
  IRVIC="irvic"
}

export enum Country {
  US = "United States of America",
}

export enum ROLE {
  SUPER_ADMIN = "Super Admin",
  SYSTEM_ADMIN = "System Admin",
  SPC_ADMIN = "SPC Admin",
  TRUST_ADMIN = "Trust Admin",
  TEMPLATE = "Templates",
  SETTINGS = "Setting",
}
