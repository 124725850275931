import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { getHttpQueryString } from "@utils/http";
import {
  EasyLienProductStatus,
  GsaBondProductStatus,
  Sku,
  SPCStatus,
  TaxProductStatus,
  NNCStatus,
  EasyLienV2ProductStatus,
} from "@models/enums";

import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class SpcService {
  constructor(private _uLawApi: ApiService) {}

  getAllUsers({ limit = 20, offset = 0, params = {} }): Observable<any> {
    const queryString = getHttpQueryString({ ...params, limit, offset });
    return this._uLawApi.get(`/admin/user/all?${queryString}`).pipe(map((res) => res.body));
  }

  getUsersByStatus(userStatus: SPCStatus): Observable<any> {
    return this._uLawApi.get(`/admin/user/all?status=${userStatus}`).pipe(map((res) => res.body));
  }

  getUserById(id: any): Observable<any> {
    return this._uLawApi.get(`/admin/user/one?userId=${id}`).pipe(map((res) => res.body));
  }

  regenerateSpcPdf(
    userId: any,
    sku: Sku,
    idGsa: any = 0,
    productId = null,
    productName = null,
  ): Observable<any> {
    let queryString =
      sku === Sku.GSA ? `userId=${userId}&sku=${sku}&idGsa=${idGsa}` : `userId=${userId}&sku=${sku}`;
    if (productId) {
      queryString += `&productId=${productId}`;
    }
    if (productName) {
      queryString += `&productName=${productName}`;
    }

    return this._uLawApi.get(`/admin/user/regenerate?${queryString}`).pipe(map((res) => res.body));
  }

  regenerateAllSpcPdf(sku: Sku): Observable<any> {
    return this._uLawApi.get(`/admin/user/regenerateall?sku=${sku}`).pipe(map((res) => res.body));
  }

  deleteSpcPdf(userId: any, sku: Sku): Observable<any> {
    return this._uLawApi
      .get(`/admin/user/deletesigneddoc?userId=${userId}&sku=${sku}`)
      .pipe(map((res) => res.body));
  }

  showSpcMergedFields(userId: any, sku: Sku): Observable<any> {
    return this._uLawApi
      .get(`/admin/user/showfields?userId=${userId}&sku=${sku}`)
      .pipe(map((res) => res.body));
  }

  sendProcessEmail({ userId, emailType, comment = "" }): Observable<any> {
    return this._uLawApi
      .get(`/admin/user/email?userId=${userId}&type=${emailType}&comment=${comment}`)
      .pipe(map((res) => res.body));
  }

  addFreeTrustToUser(userId: any, cnt: any): Observable<any> {
    return this._uLawApi
      .get(`/admin/trust/createfree?userId=${userId}&amount=${cnt}`)
      .pipe(map((res) => res.body));
  }

  deleteSpcUser(userId: any): Observable<any> {
    return this._uLawApi.get(`/admin/user/delete?userId=${userId}`).pipe(map((res) => res.body));
  }

  searchUser({ email }): Observable<any> {
    return this._uLawApi.post(`/admin/search`, { userEMail: email }).pipe(map((res) => res.body));
  }

  changeSpcStatus(userId: any, status: boolean): Observable<any> {
    return this._uLawApi.post(`/admin/user/one`, {
      id: userId,
      status: { showSpcAmendment2: status },
    });
  }

  setSpcStatus({
    userId,
    targetStatus,
    comment,
    isResetSpc = false,
    taxYear = 0,
    caseNumber = "",
    productId = null,
  }): Observable<any> {
    const reqObj = {
      userId,
      setStatus: targetStatus,
      comment,
    };

    isResetSpc && Object.assign(reqObj, { cleanFields: isResetSpc });
    taxYear && Object.assign(reqObj, { year: taxYear });
    caseNumber && Object.assign(reqObj, { casenumber: caseNumber });
    productId && Object.assign(reqObj, { productId });

    return this._uLawApi.post(`/admin/user/checkpoint`, reqObj).pipe(map((res) => res.body));
  }

  updateSpc(spc: any): Observable<any> {
    return this._uLawApi.post(`/admin/user/one`, spc).pipe(map((res) => res.body));
  }

  getCountByStatus(spcStatus: SPCStatus): Observable<any> {
    return this._uLawApi.get(`/admin/user/countspc?status=${spcStatus}`).pipe(map((res) => res.body));
  }

  getProductCountByStatus(
    sku: Sku,
    status:
      | TaxProductStatus
      | GsaBondProductStatus
      | EasyLienProductStatus
      | NNCStatus
      | EasyLienV2ProductStatus,
  ): Observable<any> {
    return this._uLawApi.get(`/admin/user/count?sku=${sku}&status=${status}`).pipe(map((res) => res.body));
  }

  getAllProductCountByStatus(payload): Observable<any> {
    return this._uLawApi.post("/admin/user/countall", payload);
  }
}
