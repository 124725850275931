import { Directive, ElementRef, Input, OnChanges, Renderer2 } from "@angular/core";

import * as _ from "lodash";

import { ROLE } from "../models";

@Directive({
  selector: "[universalUserRole]",
})
export class UserRoleDirective implements OnChanges {
  @Input("universalUserRole") roles: ROLE[];
  @Input() user: any;

  constructor(private _renderer: Renderer2, private _elRef: ElementRef) {}

  ngOnChanges(): void {
    const userRoles = _.map(_.get(this.user, "Roles", []), "name");
    this._setVisibility(userRoles, this.roles);
  }

  private _setVisibility(userRoles: any[], routeRoles: any[]): void {
    const isVisible = userRoles.some((role) => routeRoles.includes(role));
    const displayAttr = isVisible ? "block" : "none";
    this._renderer.setStyle(this._elRef.nativeElement, "display", displayAttr);
  }
}
