import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import {
  ApiService,
  AppInitService,
  EventBusService,
  JwtService,
  OverlayService,
  SessionService,
  SpcService,
  StoreService,
  TrustService,
} from "./services";
import { AuthGuard, NoAuthGuard, RoleGuard } from "./guards";
import { HttpRequestInterceptor, HttpTokenInterceptor, HttpResponseInterceptor } from "./interceptors";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    ApiService,
    JwtService,
    SpcService,
    TrustService,
    StoreService,
    OverlayService,
    AppInitService,
    SessionService,
    EventBusService,

    AuthGuard,
    NoAuthGuard,
    RoleGuard,

    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true },
  ],
})
export class ProvidersModule {}
