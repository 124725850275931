import * as _ from "lodash";

/**
 * Return the body from response
 * @param res HttpRequest response
 */
export const mapResponseBody = (res) => res.body;

export const isMobile = {
  Android: () => navigator.userAgent.match(/Android/i),
  BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
  iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
  Opera: () => navigator.userAgent.match(/Opera Mini/i),
  Windows: () => navigator.userAgent.match(/IEMobile/i),
  any: () => {
    return (
      isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows()
    );
  },
};

export const getWindowWidth = () => {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth,
  );
};

export const getWindowHeight = () => {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight,
  );
};

export const getScreenHeight = () => {
  return window.screen.height;
};

/**
 * Pick keys and values pair from object
 * @param object initial object
 * @param keys keys to pick from object that can be array or object
 */
export const pickValue = (obj, keys = {}) => {
  const newObj = {};

  if (_.isArray(keys)) {
    return _.pick(obj, keys);
  }

  Object.entries(keys).forEach(([k, v]) => {
    const value = _.get(obj, k);
    _.set(newObj, v as any, value);
  });

  return newObj;
};

/**
 * Exchange between object keys with values
 * @param object
 */
export const exchangeKeyWithValue = (obj) => {
  return Object.entries(obj).reduce((acc, cur) => {
    const [k, v] = cur;
    return { ...acc, [v as any]: k };
  }, {});
};

/**
 * Create new object with given object and reset all fields with default value
 * @param object
 */
export const resetObj = (obj) => {
  return Object.entries(obj).reduce((acc, cur) => {
    const [k, v] = cur;

    if (!v) {
      return { ...acc, [k]: null };
    }

    if (_.isString(v)) {
      return { ...acc, [k]: "" };
    }

    if (_.isArray(v)) {
      return { ...acc, [k]: [] };
    }

    if (_.isObject(v)) {
      return { ...acc, [k]: {} };
    }

    return { ...acc, [k]: null };
  }, {});
};

export const formatPhoneNumber = function (phone: string, backspace: boolean) {
  let newVal = phone.replace(/\D/g, "");
  if (backspace && newVal.length <= 6) {
    newVal = newVal.substring(0, newVal.length - 1);
  }
  if (newVal.length === 0) {
    newVal = "";
  } else if (newVal.length <= 3) {
    newVal = newVal.replace(/^(\d{0,3})/, "($1)");
  } else if (newVal.length <= 6) {
    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "($1) $2");
  } else if (newVal.length <= 10) {
    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "($1) $2-$3");
  } else {
    newVal = newVal.substring(0, 10);
    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "($1) $2-$3");
  }
  return newVal;
};

export const getPlainPhoneNumber = function (phone: string) {
  return phone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
};

export const getFolderCountByStatus = function (sku: string, status: string, dashboardReviewCounts = []) {
  if (!dashboardReviewCounts.length) return 0;
  const itemWithSameSKU = dashboardReviewCounts?.filter((folder) => {
    const keys = Object.keys(folder);
    return keys[0] === sku.toString();
  });
  const item = itemWithSameSKU.find((product) => {
    return product[sku] === status;
  });
  return item.count || 0;
};
