/**
 * Enum of Master Bond Product Status
 *
 * @enum {string} Master Bond Product Status
 *
 */
export enum MasterBondProductStatus {
  DATA_REVIEW = "Data review",
  DATA_REJECT = "Data reject",
  DOC_1_READY = "Doc 1 ready",
  DOC1_SIGN_REVIEW = "Doc 1 signature review",
  SIGNATURE_REJECTED = "Signature Rejected",
  UCC = "UCC",
  ACCEPTED = "Accepted",
  DOWNLOADED = "Downloaded",
  DELIVERED = "Delivered",
}

export enum MasterBondDocNames {
  MASTER_BOND = "masterBondP",
  MASTER_BOND_COVER_LETTER = "masterBondCoverLetter",
  MASTER_BOND_FILING = "masterBondFilling",
  MASTER_BOND_REPORT = "UCCreport",
}
