import { IrvicDocNames, IrvicStatus } from "@models/enums/irvic.enum";

export const IRVIC_APPROVAL_STATUS = [IrvicStatus.DATA_REVIEW, IrvicStatus.DOC_SIGNATURE_REVIEW];

export const IRVIC_DOCS = [
  {
    value: IrvicDocNames.DOC_1,
    label: "Doc 1",
    disable: true,
  },
  {
    value: IrvicDocNames.DOC_2,
    label: "Doc 2",
    disable: true,
  },
  {
    value: IrvicDocNames.DOC_3,
    label: "Doc 3",
    disable: true,
  },
  {
    value: IrvicDocNames.DOC_4,
    label: "Doc 4",
    disable: true,
  },
  {
    value: IrvicDocNames.DOC_5,
    label: "Doc 5",
    disable: true,
  },
];

export const IRVIC_FIELDS = [
  "loopTerms",
  "ccBizLoop",
  "presentmentTitle",
  "receiveDate",
  "presentmentName",
  "acceptDate",
  "bizName",
  "bizAddress",
  "bizCity",
  "bizState",
  "bizZip",
  "bizRegMailNum1",
  "bizRegMailNum1Ddate",
  "bizRegMailNum2",
  "bizRegMailNum2Ddate",
  "bizRegMailNum3",
  "bizRegMailNum3Ddate",
  "bizRegMailNum4",
  "bizRegMailNum4Ddate",
  "bizRegMailNum5",
  "bizRegMailNum5Ddate",
  "boe",
];

export const IRVIC_EMAIL_TYPES = [
  {
    value: "TestReviewRejected",
    label: "Test Rejected",
  },
  {
    value: "TestInProcess",
    label: "Test in Process",
  },
  {
    value: "TestTracking",
    label: "Test Tracking",
  },
  {
    value: "TestReadyForSignature",
    label: "Test Signature Required",
  },
  {
    value: "TestSignatureReviewRejected",
    label: "Test Signature Rejected",
  },
  {
    value: "TestCompleted",
    label: "Test Completed",
  },
];