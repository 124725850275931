import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpParams, HttpEventType } from "@angular/common/http";

import { Observable } from "rxjs";
import { finalize, map } from "rxjs/operators";

import { environment } from "src/environments/environment";

import { OverlayService } from "./overlay.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private _baseUrl: string;

  constructor(private readonly _http: HttpClient, private readonly _overlayService: OverlayService) {
    this._baseUrl = environment.apiURL;
  }

  getUploadProgress(event) {
    const percentDone = Math.round((100 * event.loaded) / event.total);
    this._overlayService.setUploadingPercent(percentDone);
    return { status: "progress", value: percentDone };
  }

  sendUploadResponse(event) {
    return event.body;
  }

  uploadFileEventListener(event: HttpEvent<any>): any {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.getUploadProgress(event);
      case HttpEventType.Response:
        return this.sendUploadResponse(event);
      default:
        return `File surprising upload event: ${event.type}.`;
    }
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this._http.get(`${this._baseUrl}${path}`, {
      params,
      observe: "response",
    });
  }

  downloadFile(path: string): Observable<any> {
    return this._http.get(`${this._baseUrl}${path}`, {
      responseType: "arraybuffer",
    });
  }

  put(path: string, body = {}): Observable<any> {
    return this._http.put(`${this._baseUrl}${path}`, JSON.stringify(body));
  }

  uploadFile(path: string, formData: FormData): Observable<any> {
    const filename = (formData.get("file") as File).name;
    this._overlayService.setUploadingFilename(filename);
    this._overlayService.showUploadProgress();

    return this._http
      .put(`${this._baseUrl}${path}`, formData, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(
        map(this.uploadFileEventListener.bind(this)),
        finalize(() => this._overlayService.hideUploadProgress()),
      );
  }

  post(path: string, body = {}): Observable<any> {
    return this._http.post(`${this._baseUrl}${path}`, JSON.stringify(body), {
      observe: "response",
    });
  }

  delete(path: string): Observable<any> {
    return this._http.delete(`${this._baseUrl}${path}`);
  }
}
