import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { DirectivesModule } from "src/app/directives/directives.module";

import { SidebarComponent } from "./sidebar.component";

@NgModule({
  imports: [RouterModule, CommonModule, DirectivesModule],
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
})
export class SidebarModule {}
