import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";

import { Subject } from "rxjs";

import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import { saveAs } from "file-saver";

import { IDoc } from "@models/models";
import { fileUtil, getWindowHeight } from "src/app/utils";

import { ApiService } from "@providers/services";
import * as docx from "docx-preview";

@Component({
  selector: "universal-doc-viewer",
  templateUrl: "./doc-viewer.component.html",
  styleUrls: ["./doc-viewer.component.scss"],
})
export class DocViewerComponent implements OnDestroy {
  private _stop$: Subject<any>;

  doc: IDoc;
  docSrc: any;
  docBlob: any;

  hasMergeField: any;

  width: number;
  height: number;

  isDocLoaded: boolean;

  @Input("doc")
  set setDoc(doc: IDoc) {
    this.doc = doc;
    this.setDocSrc(this.doc);
  }
  @Output() loadError: EventEmitter<any>;
  @Output() closeViewer: EventEmitter<any>;

  constructor(private _api: ApiService) {
    this.setVariables();
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  setVariables(): void {
    this._stop$ = new Subject();

    this.height = getWindowHeight() - 100;
    this.width = Math.floor((this.height * 3) / 4) + 100;

    this.loadError = new EventEmitter();
    this.closeViewer = new EventEmitter();
    this.hasMergeField = false;

    this.isDocLoaded = false;
  }

  async setDocSrc(data: IDoc): Promise<void> {
    try {
      let url = `/admin/template?sku=${data.sku}`;
      if (data.productName) url += `&productName=${data.productName}`;

      const arrayBuffer = await this._api
        .downloadFile(url)
        .pipe()
        .toPromise();

      if (data?.mergeFields) {
        this.hasMergeField = true;
        const zip = new PizZip(arrayBuffer);
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });

        doc.render({
          ...data.mergeFields,
        });
        this.docBlob = doc.getZip().generate({
          type: "blob",
          mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        docx.renderAsync(this.docBlob, document.getElementById("container"));
      } else {
        docx.renderAsync(arrayBuffer, document.getElementById("container"));
        this.docBlob = new Blob([arrayBuffer], { type: "application/docx" });
      }

      this.isDocLoaded = true;
      this.docSrc = URL.createObjectURL(this.docBlob);
    } catch (err) {
      this.loadError.emit(err);
    }
  }

  onDownloadDoc(): void {
    if (this.hasMergeField) {
      saveAs(this.docBlob, this.doc.filename);
    } else {
      fileUtil.saveFile(this.docBlob, this.doc.filename);
    }
  }

  onPrintDoc(): void {
    fileUtil.printPdf(this.docBlob);
  }

  onClose(): void {
    this.closeViewer.emit();
  }
}
