import { NncCaseType } from "../models";
import { NncCaseCategory } from "../enums";

export const NNC_CASE_TYPE: NncCaseType[] = [
  {
    label: "Civil",
    value: NncCaseCategory.CIVIL,
  },
  {
    label: "Criminal",
    value: NncCaseCategory.CRIMINAL,
  },
  {
    label: "Family",
    value: NncCaseCategory.FAMILY,
  },
];

export const NNC_DOCS = [
  { value: "Judge", label: "Notice Of Non-Consent" },
  // { value: "Clerk", label: "NNC for Clerk" },
];

export const NNC_EMAIL_TYPES = [
  {
    value: "NNCInProcess",
    label: "NNC in Process",
  },
  {
    value: "NNCReadyForSignature",
    label: "NNC Signature Required",
  },
  {
    value: "NNCSignatureReviewRejected",
    label: "NNC Signature Rejected",
  },
  {
    value: "NNCCompleted",
    label: "NNC Completed",
  },
];
