import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";

import { Observable } from "rxjs";

import { JwtService } from "../services";

@Injectable({
  providedIn: "root",
})
export class NoAuthGuard implements CanActivate {
  constructor(private _router: Router, private _jwt: JwtService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this._jwt.getToken();

    if (!token) {
      return true;
    }

    this._router.navigateByUrl("/dashboard");
    return false;
  }
}
