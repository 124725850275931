import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { HUMAN_FIELDS } from "src/app/models";
import { pickValue } from "src/app/utils";

@Component({
  selector: "universal-human",
  templateUrl: "./human.component.html",
  styleUrls: ["./human.component.scss"],
})
export class HumanComponent {
  humanForm: FormGroup;

  @Input("human")
  set setHuman(human: any) {
    const data = pickValue(human, HUMAN_FIELDS);
    this.humanForm.patchValue(data);
  }

  constructor(private readonly _fb: FormBuilder) {
    this.setHumanForm();
  }

  setHumanForm(): void {
    this.humanForm = this._fb.group({
      firstName: [{ value: "", disabled: true }],
      middleName: [{ value: "", disabled: true }],
      lastName: [{ value: "", disabled: true }],
      email: [{ value: "", disabled: true }],
      country: [{ value: "", disabled: true }],
      city: [{ value: "", disabled: true }],
      state: [{ value: "", disabled: true }],
      address: [{ value: "", disabled: true }],
    });
  }
}
