import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";

import { Observable } from "rxjs";

import * as _ from "lodash";

import { SessionService } from "../services";

@Injectable({
  providedIn: "root",
})
export class RoleGuard implements CanActivate {
  user: any;

  constructor(private _session: SessionService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { roles: routeRoles } = route.data;
    const user = this._session.getSession("@universal/user");
    const userRoles = _.map(_.get(user, "Roles", []), "name");

    const hasUserRole = userRoles.some((role) => routeRoles.includes(role));
    return hasUserRole;
  }
}
