import { ChangeDetectorRef, Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

import { finalize, tap } from "rxjs/operators";

import { NO_CB } from "@models/constants";
import { notify } from "@utils/notify";
import { SpcService } from "@providers/services";

@Component({
  selector: "universal-search-human",
  templateUrl: "./search-human.component.html",
  styleUrls: ["./search-human.component.scss"],
})
export class SearchHumanComponent {
  human: any;
  humanForm: FormGroup;

  isSearching: boolean;
  isHumanExist: boolean;

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _dialogRef: MatDialogRef<SearchHumanComponent>,
    private _spcService: SpcService,
  ) {
    this.setVariables();
    this.setHumanForm();
  }

  setVariables(): void {
    this.isSearching = false;
    this.isHumanExist = false;
  }

  setHumanForm(): void {
    this.humanForm = this._fb.group({
      email: ["", Validators.required],
      firstName: [{ value: "", disabled: true }],
      lastName: [{ value: "", disabled: true }],
    });
  }

  setHuman(human): void {
    [this.human] = human;
    this.isHumanExist = true;

    this.humanForm.patchValue({
      firstName: this.human.userFirstName,
      lastName: this.human.userLastName,
    });
  }

  onSearchHuman(): void {
    if (this.humanForm.get("email").invalid) return;

    this.isSearching = true;
    const email = this.humanForm.get("email").value;
    this._spcService
      .searchUser({ email })
      .pipe(
        tap(this.setHuman.bind(this)),
        finalize(() => (this.isSearching = false)),
      )
      .subscribe(
        () => {},
        () => notify.danger("No User Exist"),
      );
  }

  onSelectHuman(): void {
    if (!this.human) return;

    this._dialogRef.close({ human: this.human });
  }

  onCloseDialog(): void {
    this._dialogRef.close();
  }
}
