import { APP_INITIALIZER, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PreloadAllModules, RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { Title } from "@angular/platform-browser";

import { NgxSpinnerModule } from "ngx-spinner";

import { AppRoutes } from "./app.routing";
import { ProvidersModule } from "./providers/providers.module";
import { MaterialModule, SidebarModule, NavbarModule } from "./shared";
import { PdfViewerModule, DocViewerModule, UploadProgressModule } from "./components";

import { AppComponent } from "./app.component";
import { AuthLayoutComponent, AdminLayoutComponent, PageNotFoundComponent } from "./layouts";

import { AppInitService } from "./providers/services";

export function initializeApp(appInitService: AppInitService) {
  return () => appInitService.init();
}

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "legacy",
    }),

    NgxSpinnerModule,

    ProvidersModule,

    MaterialModule,
    SidebarModule,
    NavbarModule,

    PdfViewerModule,
    DocViewerModule,
    UploadProgressModule,
  ],
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent, PageNotFoundComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true,
    },
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
