import { EasyLienV2DocNames, EasyLienV2ProductStatus, Sku, TemplateProductName } from "@models/enums";

export const NOTICE_EXEMPTION_FIELDS = [
  "businessName",
  "employeeName",
  "bizAccountNum",
  "businessAddress",
  "documentNumber",
  "empID",
  "bizCity",
  "bizState",
  "bizZip",
  "bizRegMailNum1",
  "bizRegMailNum2",
  "bizRegMailNum3",
  "datePacketMailed1",
  "datePacketMailed2",
  "datePacketMailed3",
  "incidentStatement",
  "fineAmount",
  "lienState",
  "lienCountyName",
  "filingNumber",
  "crFeeAmountSpelled",
  "boe",
];

export const NOTICE_EXEMPTION_FIELDS_V1 = [
  "businessName",
  "bizRegMailNum1",
  "businessAddress",
  "bizCity",
  "bizState",
  "bizZip",
  "incidentStatement",
];

export const NOTICE_DEFAULT_FIELDS = [
  "bizRegMailNum2",
  "incidentDate",
  "bizRegMailNum3",
  "employeeName",
  "empID",
];

export const EASY_LIEN_DOCS = [
  {
    value: EasyLienV2DocNames.DOC1_NAME,
    label: "Notice of Copyright",
    fileName: "Copyright_Notice",
    index: "01",
  },
  {
    value: EasyLienV2DocNames.DOC2_NAME,
    label: "Notice of Default",
    fileName: "Notice_Of_Default",
    index: "02",
  },
  { value: EasyLienV2DocNames.DOC3_NAME, label: "Notice of Lien", fileName: "Notice_Of_Lien", index: "03" },
  { value: EasyLienV2DocNames.FINAL_PACKET, label: "Easy Lien Packet", fileName: "Lien_Filing", index: "04" },
  {
    value: EasyLienV2DocNames.UCC_LIEN_FILING,
    label: "Easy Lien UCC Filing",
    fileName: "UCC_Lien_Filing",
    index: "05",
  },
  {
    value: EasyLienV2DocNames.UCC_REPORT,
    label: "Easy Lien UCC Report",
    fileName: "UCC_Lien_Report",
    index: "06",
  },
];

export const EASY_LIEN_MAILING_DOCS = [
  {
    value: EasyLienV2DocNames.MAIL_RECEIPT_1,
    label: "Notice of Copyright Mail Receipt",
  },
  {
    value: EasyLienV2DocNames.MAIL_TRACKING_1,
    label: "Notice of Copyright Mail Tracking Receipt",
  },
  {
    value: EasyLienV2DocNames.MAIL_RECEIPT_2,
    label: "Notice of Default Mail Receipt",
  },
  {
    value: EasyLienV2DocNames.MAIL_TRACKING_2,
    label: "Notice of Default Mail Tracking Receipt",
  },
  {
    value: EasyLienV2DocNames.MAIL_RECEIPT_3,
    label: "Notice of Lien Mail Receipt",
  },
  {
    value: EasyLienV2DocNames.MAIL_TRACKING_3,
    label: "Notice of Lien Tracking Receipt",
  },
];

export const EASY_LIEN_APPROVAL_STATUS = [
  EasyLienV2ProductStatus.DOC1_REVIEW,
  EasyLienV2ProductStatus.DOC1_SIGN_REVIEW,
  EasyLienV2ProductStatus.DOC2_SIGN_REVIEW,
  EasyLienV2ProductStatus.DOC3_SIGN_REVIEW,
];

export const SHOW_EASY_LIEN_MARGE_FIELD = [
  {
    label: "Lien UCC-1 Filing",
    value: {
      sku: Sku.EASY_LIEN_V2,
      productName: TemplateProductName.LIEN_FILLING,
    },
  },
];

export const EASY_LIEN_EMAIL_TYPES = [
  {
    value: "EasyLienReviewRejected",
    label: "Easy Lien Rejected",
  },
  {
    value: "EasyLienInProcess",
    label: "Easy Lien in Process",
  },
  {
    value: "EasyLienSubmittedToUCC",
    label: "Easy Lien Submitted to UCC",
  },
  {
    value: "EasyLienReadyForSignature",
    label: "Easy Lien Signature Required",
  },
  {
    value: "EasyLienSignatureReviewRejected",
    label: "Easy Lien Signature Rejected",
  },
  {
    value: "EasyLienCompleted",
    label: "Easy Lien Completed",
  },
];
