import { TrustPdfType, TrustStatus } from "../enums";

export const TRUST_STATUS_IN_REVIEW = [TrustStatus.IN_REVIEW, TrustStatus.SIGNATURE_REVIEW];

export const TRUST_DOCS = [
  { value: TrustPdfType.ORIGIN, label: "Origin" },
  { value: TrustPdfType.CHANGED, label: "Changed" },
];

export const TRUST_EMAIL_TYPES = [
  {
    value: "TrustInProcess",
    label: "Trust in Process",
  },
  {
    value: "TrustSubmittedToUCC",
    label: "Trust Submitted to UCC",
  },
  {
    value: "TrustReadyForSignature",
    label: "Trust Signature Required",
  },
  {
    value: "TrustSignatureReviewRejected",
    label: "Trust Signature Rejected",
  },
  {
    value: "TrustCompleted",
    label: "Trust Completed",
  },
];

export const TARGET_TRUST_STATUS = {
  approve: {
    [TrustStatus.IN_REVIEW]: TrustStatus.SUBMITTED_TO_UCC,
    [TrustStatus.SIGNATURE_REVIEW]: TrustStatus.COMPLETED,
  },
  reject: {
    [TrustStatus.IN_REVIEW]: TrustStatus.IN_PROCESS,
    [TrustStatus.SIGNATURE_REVIEW]: TrustStatus.SIGNATURE_REQUIRED,
  },
};

export const TRUST_BASIC_INFO_FIELDS = {
  trustName: "name",
  trustNotaryState: "notaryState",
  trustNotaryCounty: "notaryCounty",
  trustStatus: "status",
  CommonLawNumber: "lawNum",
  "ucc.envelopeId": "envelopeId",
  "ucc.filingNumber": "uccFileNum",
  notNeedUcc: "notFilingUcc",
};

export const TRUST_HUMAN_FIELDS = {
  userFirstName: "firstName",
  userMiddleName: "middleName",
  userLastName: "lastName",
  userEMail: "email",
  userPhone: "phone",
  "status.status": "status",
  userAddressLine1: "addr",
  userCity: "city",
  userState: "state",
  userPostalCode: "postalCode",
};

export const TRUST_IN_CHANGE_STATUS_LIST = [
  TrustStatus.PROTECTOR_CHANGE,
  TrustStatus.TRUSTEE_CHANGE,
  TrustStatus.BENEFICIARY_ADD,
  TrustStatus.BENEFICIARY_REPLACE,
  TrustStatus.BENEFICIARY_REMOVED,
  TrustStatus.PROTECTOR_VERIFIED,
  TrustStatus.TRUSTEE_VERIFIED,
  TrustStatus.BENEFICIARY_ADD_VERIFIED,
  TrustStatus.BENEFICIARY_REPLACE_VERIFIED,
  TrustStatus.BENEFICIARY_REMOVED_VERIFIED,
  TrustStatus.PA_SENT_TO_UCC,
  TrustStatus.TRE_SENT_TO_UCC,
  TrustStatus.BFA_SENT_TO_UCC,
  TrustStatus.BF_ADD_SENT_TO_UCC,
  TrustStatus.BF_R_SENT_TO_UCC,
];
