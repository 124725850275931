import { Sku } from "../models";
import { notify } from "./notify";

/**
 * When download file, check the browser.
 *
 * @return {*}  {MethodDecorator}
 */
export function DownloadFileBrowserCase(): MethodDecorator {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originFn = target[propertyKey];
    // eslint-disable-next-line no-param-reassign
    descriptor.value = function (...args) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob.apply(null, args);
        return;
      }

      originFn.apply(this, args);
    };
  };
}

export function SpcProductExistence(sku: Sku, notifyMsg = ""): MethodDecorator {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originFn = target[propertyKey];
    // eslint-disable-next-line no-param-reassign
    descriptor.value = function (...args) {
      const isProductExist = this.isProductExist(sku);

      if (!isProductExist) {
        !!notifyMsg && notify.warning(notifyMsg);
        return;
      }

      originFn.apply(this, args);
    };
  };
}
