export class Filter {
  /**
   * Filter list by key
   *
   * @static
   * @param {string} key
   * @param {Array} list
   * @return {Array}
   * @memberof Filter
   */
  static filterByKey(key, list): any[] {
    const filterKey = key.toLowerCase();
    return [...(list || [])].filter((el) => el.toLowerCase().includes(filterKey));
  }
}
