import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { Location, PopStateEvent } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { CdkPortal } from "@angular/cdk/portal";

import { combineLatest, forkJoin, Subscription } from "rxjs";
import { filter, tap } from "rxjs/operators";

import { NgxSpinnerService } from "ngx-spinner";
import PerfectScrollbar from "perfect-scrollbar";

import { DASHBOARD_FOLDERS, NO_CB } from "@models/constants";
import { IDoc, INavItem, IPdf } from "@models/models";
import { NavItemType, Sku, SPCStatus } from "@models/enums";

import { getDialogConfig, getViewerOverlaySize, notify } from "src/app/utils";

import { EventBusService, OverlayService, SpcService, StoreService, TrustService } from "@providers/services";
import { UploadProgressComponent } from "src/app/components";

import { NavbarComponent } from "../../shared/navbar/navbar.component";

@Component({
  selector: "app-layout",
  templateUrl: "./admin-layout.component.html",
})
export class AdminLayoutComponent implements OnInit, AfterViewInit {
  public navItems: INavItem[];
  private _router: Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  url: string;
  location: Location;

  // overlay variables
  overlayRef: OverlayRef;
  doc: IDoc;
  pdf: IPdf;

  @ViewChild("sidebar", { static: false }) sidebar: any;
  @ViewChild(NavbarComponent, { static: false }) navbar: NavbarComponent;
  @ViewChild("docViewerPortal", { static: false }) docViewerPortal: CdkPortal;
  @ViewChild("pdfViewerPortal", { static: false }) pdfViewerPortal: CdkPortal;

  constructor(
    private router: Router,
    location: Location,
    private _overlay: Overlay,
    private _dialog: MatDialog,
    private _spinner: NgxSpinnerService,
    private _uLawState: StoreService,
    private _uLawEvent: EventBusService,
    private _uLawOverlay: OverlayService,
    private _uLawSpcService: SpcService,
    private _uLawTrustService: TrustService,
  ) {
    this.location = location;
  }

  ngOnInit() {
    this.initEventBusListener();
    this.initOverlayListener();
    this.fetchProcessReviewCnt();

    const elemMainPanel = <HTMLElement>document.querySelector(".main-panel");
    const elemSidebar = <HTMLElement>document.querySelector(".sidebar .sidebar-wrapper");
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (event instanceof NavigationEnd) {
        if (event.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
      }
    });

    this._router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        elemMainPanel.scrollTop = 0;
        elemSidebar.scrollTop = 0;
      });

    const html = document.getElementsByTagName("html")[0];

    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      // let ps = new PerfectScrollbar(elemMainPanel);
      // ps = new PerfectScrollbar(elemSidebar);
      html.classList.add("perfect-scrollbar-on");
    } else {
      html.classList.add("perfect-scrollbar-off");
    }

    this._router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.navbar.sidebarClose();
      });

    this.navItems = [
      {
        type: NavItemType.NavbarLeft,
        title: "Dashboard",
        iconClass: "fa fa-dashboard",
      },

      {
        type: NavItemType.NavbarRight,
        title: "",
        iconClass: "fa fa-bell-o",
        numNotifications: 5,
        dropdownItems: [
          { title: "Notification 1" },
          { title: "Notification 2" },
          { title: "Notification 3" },
          { title: "Notification 4" },
          { title: "Another Notification" },
        ],
      },
      {
        type: NavItemType.NavbarRight,
        title: "",
        iconClass: "fa fa-list",

        dropdownItems: [
          { iconClass: "pe-7s-mail", title: "Messages" },
          { iconClass: "pe-7s-help1", title: "Help Center" },
          { iconClass: "pe-7s-tools", title: "Settings" },
          "separator",
          { iconClass: "pe-7s-lock", title: "Lock Screen" },
          { iconClass: "pe-7s-close-circle", title: "Log Out" },
        ],
      },
      {
        type: NavItemType.NavbarLeft,
        title: "Search",
        iconClass: "fa fa-search",
      },

      { type: NavItemType.NavbarLeft, title: "Account" },
      {
        type: NavItemType.NavbarLeft,
        title: "Dropdown",
        dropdownItems: [
          { title: "Action" },
          { title: "Another action" },
          { title: "Something" },
          { title: "Another action" },
          { title: "Something" },
          "separator",
          { title: "Separated link" },
        ],
      },
      { type: NavItemType.NavbarLeft, title: "Log out" },
    ];
  }

  ngAfterViewInit() {
    this.runOnRouteChange();
  }

  initEventBusListener(): void {
    this._uLawEvent.isShowLoadingSpinner
      .pipe(
        tap((isShow: boolean) => {
          isShow && this._spinner.show();
          !isShow && this._spinner.hide();
        }),
      )
      .subscribe(NO_CB);
  }

  public isMap() {
    const isMapScreen = this.location.prepareExternalUrl(this.location.path()) === "/maps/fullscreen";
    return isMapScreen;
  }

  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector(".sidebar .sidebar-wrapper");
      const elemMainPanel = <HTMLElement>document.querySelector(".main-panel");
      let ps = new PerfectScrollbar(elemMainPanel);
      ps = new PerfectScrollbar(elemSidebar);
      ps.update();
    }
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }
  // TODO: ask Pasha, how to get service products count in review status
  fetchProcessReviewCnt(): void {
    const payload = [];
    DASHBOARD_FOLDERS.forEach((product) => {
      product.folders.forEach((item) => {
        const newSku = [SPCStatus.AUTHENTICATION_REVIEW, SPCStatus.AUTHENTICATION_SIGNATURE_REVIEW].includes(
          item.status,
        )
          ? Sku.SPC_AMENDMENT
          : product.sku;
        payload.push({
          [newSku]: item.status,
        });
      });
    });
    forkJoin({
      productCounts: this._uLawSpcService.getAllProductCountByStatus(payload),
    })
      .pipe(tap(this.setProcessReviewCnt.bind(this)))
      .subscribe(NO_CB);
  }

  setProcessReviewCnt(data: any): void {
    const { productCounts } = data;

    this._uLawState.setDashboardReviewCount(productCounts.body || []);
  }

  initOverlayListener(): void {
    combineLatest([
      this._uLawOverlay.doc.pipe(tap(this.setDoc.bind(this))),
      this._uLawOverlay.pdf.pipe(tap(this.setPdf.bind(this))),
      this._uLawOverlay.isShowUploadProgress.pipe(tap(this.setUploadProgressStatus.bind(this))),
    ]).subscribe(NO_CB);
  }

  setUploadProgressStatus(isShow: boolean): void {
    if (!isShow) return;

    const uploadProgressDialogConfig = getDialogConfig({
      width: "320px",
      position: { top: "24px", right: "16px" },
      panelClass: "upload-progress",
      backdropClass: "upload-progress-back-drop",
    });
    this._dialog.open(UploadProgressComponent, uploadProgressDialogConfig);
  }

  setDoc(doc: IDoc): void {
    this.doc = doc;

    const { width, height } = getViewerOverlaySize();
    this.overlayRef = this.getOverlayRef(height, width);
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose();
    });
    this.overlayRef.attach(this.docViewerPortal);
  }

  onLoadDocError(): void {
    this.overlayRef.dispose();
    notify.danger("This document does not exist.");
  }

  setPdf(pdf: IPdf): void {
    this.pdf = pdf;

    const { width, height } = getViewerOverlaySize();
    this.overlayRef = this.getOverlayRef(height, width);
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose();
    });
    this.overlayRef.attach(this.pdfViewerPortal);
  }

  onLoadPdfError(): void {
    this.overlayRef.dispose();
    notify.danger("This PDF does not exist.");
  }

  disposeOverlay(): void {
    this.overlayRef.dispose();
  }

  getOverlayRef(height: string, width: string) {
    const positionStrategy = this._overlay.position().global().centerHorizontally().centerVertically();

    const overlayConfig = new OverlayConfig({
      positionStrategy,
      height,
      width,
    });
    overlayConfig.hasBackdrop = true;

    const overlayRef = this._overlay.create(overlayConfig);
    return overlayRef;
  }
}
