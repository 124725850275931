import { Injectable } from "@angular/core";

import { Subject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class EventBusService {
  isShowLoadingSpinner$: Subject<boolean> = new Subject();
  isShowLoadingSpinner = this.isShowLoadingSpinner$.asObservable().pipe(distinctUntilChanged());

  showLoadingSpinner(): void {
    this.isShowLoadingSpinner$.next(true);
  }

  hideLoadingSpinner(): void {
    this.isShowLoadingSpinner$.next(false);
  }
}
