import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Sku, TrustStatus } from "@models/enums";
import { getHttpParams, getHttpQueryString } from "@utils/http";

import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class TrustService {
  constructor(private _uLawApi: ApiService) {}

  getAll({ limit = 20, offset = 0, params = {} }): Observable<any> {
    const queryString = getHttpQueryString({ ...params, limit, offset });
    return this._uLawApi.get(`/admin/trust/all?${queryString}`).pipe(map((res) => res.body));
  }

  getById(trustId: any): Observable<any> {
    return this._uLawApi.get(`/admin/trust?trustId=${trustId}`).pipe(map((res) => res.body));
  }

  getByStatus(trustStatus: TrustStatus): Observable<any> {
    return this._uLawApi.get(`/admin/trust/all?trustStatus=${trustStatus}`).pipe(map((res) => res.body));
  }

  update(trust: any): Observable<any> {
    return this._uLawApi.post(`/admin/trust/update`, trust).pipe(map((res) => res.body));
  }

  delete(trustId: any): Observable<any> {
    return this._uLawApi.get(`/admin/trust/delete?trustId=${trustId}`).pipe(map((res) => res.body));
  }

  resendRoleRequests(trustId: any): Observable<any> {
    return this._uLawApi.get(`/admin/trust/resendroles?trustId=${trustId}`).pipe(map((res) => res.body));
  }

  regenerateTrustPdf({ trustId, isChangedPdf, changedPdfOffset }): Observable<any> {
    const path = isChangedPdf
      ? `/admin/trust/regenerate?trustId=${trustId}&changedoc=${isChangedPdf}&offset=${changedPdfOffset}`
      : `/admin/trust/regenerate?trustId=${trustId}`;

    return this._uLawApi.get(path).pipe(map((res) => res.body));
  }

  getTrustPdfMergedFields({ trustId, isChangedPdf, changedPdfOffset }): Observable<any> {
    const path = isChangedPdf
      ? `/admin/trust/showfields?trustId=${trustId}&changedoc=${isChangedPdf}&offset=${changedPdfOffset}`
      : `/admin/trust/showfields?trustId=${trustId}`;

    return this._uLawApi.get(path).pipe(map((res) => res.body));
  }

  setTrustStatus({ trustId, comment, targetStatus }): Observable<any> {
    return this._uLawApi
      .post(`/admin/trust/checkpoint`, {
        trustId,
        comment,
        setStatus: targetStatus,
      })
      .pipe(map((res) => res.body));
  }

  getCountByStatus(status: TrustStatus): Observable<any> {
    return this._uLawApi.get(`/admin/trust/count?status=${status}`).pipe(map((res) => res.body));
  }

  undoChange(id: any): Observable<any> {
    return this._uLawApi.get(`/admin/trust/changerevert?trustId=${id}`);
  }

  updateStatus(role, trustId, userId): Observable<any> {
    if (["Admin", "Beneficiary"].includes(role)) {
      return this._uLawApi.get(
        `/admin/trust/authorizemanual?role=${role}&trustId=${trustId}&userId=${userId}`,
      );
    }
    return this._uLawApi.get(`/admin/trust/authorizemanual?role=${role}&trustId=${trustId}`);
  }
}
