import { Component, OnInit } from "@angular/core";

import PerfectScrollbar from "perfect-scrollbar";

import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

import { ROLE } from "@models/enums";
import { NO_CB } from "@models/constants";

import { UserService } from "@providers/services";

declare const $: any;

// Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
  roles?: ROLE[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
  roles?: ROLE[];
}

// Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "dashboard",
    roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN, ROLE.SPC_ADMIN, ROLE.TRUST_ADMIN],
  },
  {
    path: "/spc-process",
    title: "SPC Process",
    type: "link",
    icontype: "group",
    roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN, ROLE.SPC_ADMIN],
  },
  {
    path: "/trust-process",
    title: "Trust Process",
    type: "link",
    icontype: "verified_user",
    roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN, ROLE.TRUST_ADMIN],
  },
  {
    path: "/nda",
    title: "Non-Disclosure Agreement",
    type: "link",
    icontype: "gavel",
    roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN],
  },
  {
    path: "/irvic",
    title: "Test",
    type: "link",
    icontype: "settings_alert",
    roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN],
  },
  {
    path: "/services",
    title: "Services",
    type: "sub",
    icontype: "miscellaneous_services",
    collapse: "services",
    roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN, ROLE.SPC_ADMIN],
    children: [
      {
        path: "nnc",
        title: "Notice Of Non-Consent",
        ab: "N",
      },
      {
        path: "master-bond",
        title: "Master Bond",
        ab: "M",
      },
      {
        path: "tax-settlement-v1",
        title: "Tax Settlement V1",
        ab: "T",
      },
      {
        path: "tax-settlement",
        title: "Tax Settlement V2",
        ab: "T",
      },

      {
        path: "gsa-bond-v1",
        title: "GSA Bond V1",
        ab: "G",
      },
      {
        path: "gsa-bond",
        title: "GSA Bond V2",
        ab: "G",
      },
      {
        path: "easy-lien-v1",
        title: "Easy Lien V1",
        ab: "E",
      },
      {
        path: "easy-lien",
        title: "Easy Lien V2",
        ab: "E",
      },
    ],
  },
  {
    path: "/settings",
    title: "Settings",
    type: "sub",
    icontype: "settings",
    collapse: "settings",
    roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN, ROLE.SETTINGS, ROLE.TEMPLATE],
    children: [
      {
        path: "system-basic",
        title: "System Basic",
        ab: "S",
        roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN],
      },
      {
        path: "templates",
        title: "Templates",
        ab: "T",
        roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN, ROLE.SETTINGS, ROLE.TEMPLATE],
      },
    ],
  },
  {
    path: "/user-management",
    title: "User Management",
    type: "sub",
    icontype: "account_box",
    collapse: "user-management",
    roles: [ROLE.SUPER_ADMIN],
    children: [
      { path: "roles", title: "Roles", ab: "R" },
      { path: "admins", title: "Admins", ab: "A" },
    ],
  },
];

@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  private _stop$: Subject<any>;

  public menuItems: any[];
  ps: any;

  user: any;

  constructor(private readonly _userService: UserService) {
    this._setVariables();
  }

  ngOnInit() {
    this._userService.user
      .pipe(
        tap((user) => (this.user = user)),
        takeUntil(this._stop$),
      )
      .subscribe(NO_CB);

    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector(".sidebar .sidebar-wrapper");
      this.ps = new PerfectScrollbar(elemSidebar);
    }
  }

  private _setVariables(): void {
    this._stop$ = new Subject();
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }
}
