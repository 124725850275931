import { Directive, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";
import { formatPhoneNumber } from "@utils/common";

@Directive({
  selector: "[ulawPhoneMask]",
})
export class PhoneMaskDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener("ngModelChange", ["$event"])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener("keydown.backspace", ["$event"])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(value, backspace) {
    const newVal = formatPhoneNumber(value, backspace);
    this.ngControl.valueAccessor.writeValue(newVal);
  }
}
