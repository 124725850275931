import { getWindowHeight, getWindowWidth, isMobile } from "./common";

/**
 * Return DOC & Pdf overlay viewer size.
 * If desktop version, width: window width - 480, height: window height - 24.
 * If mobile version, width: window width - 24, height: window height - 24.
 *
 * @return {string, string} width and height of the overlay
 */
export const getViewerOverlaySize = () => {
  const isMobileView = Boolean(isMobile.any());
  const windowWidth = getWindowWidth();
  const windowHeight = getWindowHeight();

  const width = isMobileView ? `${windowWidth - 24}px` : `${windowWidth - 480}px`;
  const height = `${windowHeight - 24}px`;
  return { width, height };
};
