import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StoreService {
  private _countries$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  countries = this._countries$.asObservable();

  private _states$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  states = this._states$.asObservable();

  private _currencies$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  currencies = this._currencies$.asObservable();

  private _dashboardReviewCount$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  dashboardReviewCount = this._dashboardReviewCount$.asObservable();

  setCountries(countries: any[]): void {
    this._countries$.next(countries);
  }

  setStates(states: any[]): void {
    this._states$.next(states);
  }

  setCurrencies(currencies: any[]): void {
    this._currencies$.next(currencies);
  }

  setDashboardReviewCount(count: any[]): void {
    this._dashboardReviewCount$.next(count);
  }
}
