import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { PdfViewerModule as PDFViewerModule } from "ng2-pdf-viewer";

import { PdfViewerComponent } from "./pdf-viewer.component";

@NgModule({
  declarations: [PdfViewerComponent],
  imports: [CommonModule, PDFViewerModule],
  exports: [PdfViewerComponent],
})
export class PdfViewerModule {}
