import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";

import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

import set from "lodash/set";

import { EventBusService } from "@providers/services";

const PUT_JSON_REQUESTS = [
  "elienone",
  "/nnc",
  "/admin/user/productstatus",
  "/user/elien2v",
  "/user/gsa2v",
  "user/tax2v",
  "/user/irvic",
];

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  activeRequestsCnt: number;

  constructor(private _uLawEvent: EventBusService) {
    this.setVariables();
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers = {};

    const { method, url } = req;
    const isPutJSONRequest = PUT_JSON_REQUESTS.some((el) => url.includes(el));

    if (method !== "PUT" || isPutJSONRequest) {
      set(headers, "Content-Type", "application/json; charset=utf-8");
      set(headers, "Accept", "application/json");
    }

    const request = req.clone({ setHeaders: headers });

    this.activeRequestsCnt += 1;
    if (this.activeRequestsCnt > 0) {
      this._uLawEvent.showLoadingSpinner();
    }

    return next.handle(request).pipe(finalize(this.finalizeRequest.bind(this)));
  }

  setVariables(): void {
    this.activeRequestsCnt = 0;
  }

  finalizeRequest(): void {
    this.activeRequestsCnt -= 1;
    if (this.activeRequestsCnt === 0) {
      this._uLawEvent.hideLoadingSpinner();
    }
  }
}
