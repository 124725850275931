import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { IConfirmDialogData, Status } from "src/app/models";

@Component({
  selector: "universal-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent {
  constructor(
    private _dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData,
  ) {}

  onConfirm(): void {
    this._dialogRef.close({ status: Status.SUCCESS });
  }

  onDecline(): void {
    this._dialogRef.close({ status: Status.DECLINE });
  }
}
