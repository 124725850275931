import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { forkJoin } from "rxjs";
import { tap } from "rxjs/operators";

import { Country } from "src/app/models";
import { StoreService } from "./store.service";

@Injectable({
  providedIn: "root",
})
export class AppInitService {
  constructor(private readonly _http: HttpClient, private readonly _store: StoreService) {}

  init(): Promise<any> {
    return new Promise<any>((resolve) => {
      forkJoin({
        countries: this._http.get("assets/json/countries.json"),
        currencies: this._http.get("assets/json/currencies.json"),
      })
        .pipe(
          tap(({ countries, currencies }) => {
            this._setCountries(countries);
            this._setCurrencies(currencies);
          }),
        )
        .subscribe(resolve);
    });
  }

  private _setCountries(countries): void {
    this._store.setCountries(countries);

    const { states } = [...countries].find((co) => co.name === Country.US);
    this._store.setStates(states);
  }

  private _setCurrencies(currencies): void {
    const data = Object.values(currencies);
    this._store.setCurrencies(data);
  }
}
