import { DownloadFileBrowserCase } from "./decorators";

class FileUtil {
  /**
   * Download blob file with given name
   * @param {Blob} blob The blob of file which is being saved
   * @param {string} filename The filename of file which is being saved
   * @memberof FileUtil
   */
  @DownloadFileBrowserCase()
  saveFile(blob: Blob, filename: string): void {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display:none;");
    const url = window.URL.createObjectURL(blob);
    const extension = blob.type.split("/")[1];
    a.href = url;
    a.download = `${filename}.${extension}`;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  /**
   * Print blob file with given name
   * @param {Blob} blob The blob of file which is being printed
   * @memberof FileUtil
   */
  @DownloadFileBrowserCase()
  printPdf(blob: Blob): void {
    const url = window.URL.createObjectURL(blob);
    window.open(url).print();
  }
}

export const fileUtil = new FileUtil();
