/**
 * Enum of GSA Bond Product Status
 *
 * @enum {string} GSA Bond Product Status
 *
 */
export enum GsaBondProductStatus {
  DATA_REVIEW = "Data review",
  DATA_REJECT = "Data reject",
  DOC1_READY = "Doc 1 ready",
  DOC1_SIGN_REVIEW = "Doc 1 signature review",
  DOC1_DOWNLOADED = "Doc 1 Downloaded",
  GSA_TO_UCC = "GSA to UCC",
  GSA_ACCEPTED = "GSA Accepted",
  DOC2_READY = "Doc 2 ready",
  DOC2_TRACKING = "Doc 2 tracking",
  DOC2_DELIVERED = "Doc 2 Delivered",
  DOC3_READY = "Doc 3 ready",
  DOC3_DOWNLOADED = "Doc 3 Downloaded",
  DOC3_SIGN_REVIEW = "Doc 3 signature review",
  DOC4_READY = "Doc 4 ready",
  DOC4_TRACKING = "Doc 4 tracking",
  DOC4_DELIVERED = "Doc 4 Delivered",
  COMPLETE = "Complete",
}

export enum GsaBondStatus {
  REVIEW = "First",
  REJECTED = "Rejected",
  ACCEPTED = "Accepted",
  DOWNLOADED = "DOWNLOADED",
}

export enum GSA2vDocNames {
  BC_AND_SPC = "BcAndSpcPack",
  A_PACK = "1099-aPack",
  COURT_JUDGE = "CourtJudjePack",
  COURT_IRS = "CourtIrsPack",
  COURT_DTC = "CourtDtcPack",
  COURT_TREASURY = "CourtTreasuryPack",
  IRS_JUDGE = "IrsFormJudjePack",
  IRS_IRS = "IrsFormIrsPack",
  IRS_DTC = "IrsFormDtcPack",
  IRS_TREASURY = "IrsFormTreasuryPack",
  JUDGE_MAIL_RECEIPT = "judgeMailReceipt",
  JUDGE_TRACKING = "judgeTracking",
  IRS_MAIL_RECEIPT = "irsMailReceipt",
  IRS_TRACKING = "irsTracking",
  DTC_MAIL_RECEIPT = "dtcMailReceipt",
  DTC_TRACKING = "dtcTracking",
  TREASURY_MAIL_RECEIPT = "treasuryMailReceipt",
  TREASURY_TRACKING = "treasuryTracking",
  SETT_OF_LITIGATION = "settlementOfLitigation",
  JUDGE_MAIL_RECEIPT2 = "judgeMailReceipt2",
  JUDGE_TRACKING2 = "judgeTracking2",
  UCC_FILING = "UccFilling",
  UCC_REPORT = "UCCreport",
}

export enum GSA2vRegDocNames {
  W9_COVER_LETTER = "w9CoverLetter",
  GSA_COVER_LETTER = "gsaCoverLetter",
  GSA_JUDICIAL_REVIEW = "gsaJudicialReview",
  GSA_DECLARATION_FACTS = "gsaDeclarationOfFacts",
  GSA_FIDUCIARY_CONTRACT_DISTRICT_COURT = "gsaFiduciaryContractDistrictCourt",
  GSA_FIDUCIARY_CONTRACT_IRS = "gsaFiduciaryContractIrs",
  GSA_FIDUCIARY_CONTRACT_DTC = "gsaFiduciaryContractDtc",
  GSA_FIDUCIARY_CONTRACT_TREASURY = "gsaFiduciaryContractTreasury",
  AFFIDAVIT_OF_OWNERSHIP = "affidavitofOwnership",
  PROOF_OF_SERVICE = "proofOfService",
  SETT_OF_LITIGATION = "settlementOfLitigation",
  F56 = "f56",
  W9 = "w9",
  F1040V = "f1040V",
  OF91 = "of91",
  SF24 = "sf24",
  SF25 = "sf25",
  SF25A = "sf25A",
  SF28 = "sf28",
}

export enum GsaCaseCategory {
  CRIMINAL = "Criminal",
  CIVIL = "Civil",
}
