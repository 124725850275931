import {
  GsaBondProductStatus,
  GSA2vDocNames,
  GSA2vRegDocNames,
  GsaBondStatus,
  GsaCaseCategory,
  Sku,
  TemplateProductName,
} from "../enums";

export const GSA_REVIEW_STATUS_LIST = [GsaBondStatus.REVIEW];

export const GSA_READY_STATUS_LIST = [GsaBondStatus.ACCEPTED, GsaBondStatus.DOWNLOADED];

export const GSA_BOND_APPROVAL_STATUS = [
  GsaBondProductStatus.DATA_REVIEW,
  GsaBondProductStatus.DOC1_SIGN_REVIEW,
  GsaBondProductStatus.DOC3_SIGN_REVIEW,
];

export const GSA_BOND_SIGNED_DOC = [
  {
    value: GSA2vDocNames.BC_AND_SPC,
    label: " Birth Certificate and SPC",
  },
  {
    value: GSA2vDocNames.A_PACK,
    label: "1099-A",
  },
  {
    value: GSA2vDocNames.COURT_JUDGE,
    label: "Court judge",
  },
  {
    value: GSA2vDocNames.COURT_IRS,
    label: "Court Irs",
  },
  {
    value: GSA2vDocNames.COURT_DTC,
    label: "Court Dtc",
  },
  {
    value: GSA2vDocNames.COURT_TREASURY,
    label: "Court Treasury",
  },
  {
    value: GSA2vDocNames.IRS_JUDGE,
    label: "Irs Form Judge",
  },
  {
    value: GSA2vDocNames.IRS_IRS,
    label: "Irs Form Irs",
  },
  {
    value: GSA2vDocNames.IRS_DTC,
    label: "Irs Form Dtc",
  },
  {
    value: GSA2vDocNames.IRS_TREASURY,
    label: "Irs Form Treasury",
  },
  {
    value: GSA2vDocNames.SETT_OF_LITIGATION,
    label: "Demand For Case Closure",
  },
  {
    value: GSA2vDocNames.UCC_FILING,
    label: "UCC Filing",
  },
  {
    value: GSA2vDocNames.UCC_REPORT,
    label: "UCC Report",
  },
  {
    value: GSA2vRegDocNames.F56,
    label: "F56",
  },
  {
    value: GSA2vRegDocNames.W9,
    label: "W9",
  },
  {
    value: GSA2vRegDocNames.F1040V,
    label: "F1040V",
  },
  {
    value: GSA2vRegDocNames.OF91,
    label: "OF91",
  },
  {
    value: GSA2vRegDocNames.SF24,
    label: "SF24",
  },
  {
    value: GSA2vRegDocNames.SF25,
    label: "SF25",
  },
  {
    value: GSA2vRegDocNames.SF25A,
    label: "SF25A",
  },
  {
    value: GSA2vRegDocNames.SF28,
    label: "SF28",
  },
];

export const GSA_DELETE_DOC = [
  {
    value: GSA2vDocNames.JUDGE_MAIL_RECEIPT,
    label: "Judge Mail Receipt",
  },
  {
    value: GSA2vDocNames.JUDGE_TRACKING,
    label: "Judge Tracking",
  },
  {
    value: GSA2vDocNames.IRS_MAIL_RECEIPT,
    label: "IRS Mail Receipt",
  },
  {
    value: GSA2vDocNames.IRS_TRACKING,
    label: "IRS Tracking",
  },
  {
    value: GSA2vDocNames.DTC_MAIL_RECEIPT,
    label: "DTC Mail Receipt",
  },
  {
    value: GSA2vDocNames.DTC_TRACKING,
    label: "DTC Tracking",
  },
  {
    value: GSA2vDocNames.TREASURY_MAIL_RECEIPT,
    label: "Treasury Mail Receipt",
  },
  {
    value: GSA2vDocNames.TREASURY_TRACKING,
    label: "Treasury Tracking",
  },
  {
    value: GSA2vDocNames.JUDGE_MAIL_RECEIPT2,
    label: "Judge Mail Receipt 2",
  },
  {
    value: GSA2vDocNames.JUDGE_TRACKING2,
    label: "Judge Tracking 2",
  },
];

export const GSA_REGENERATE_DOC = [
  {
    value: GSA2vRegDocNames.W9_COVER_LETTER,
    label: "W9 Cover Letter",
  },
  {
    value: GSA2vRegDocNames.GSA_COVER_LETTER,
    label: "GSA Cover Letter",
  },
  {
    value: GSA2vRegDocNames.GSA_JUDICIAL_REVIEW,
    label: "GSA Judicial Review",
  },
  {
    value: GSA2vRegDocNames.GSA_DECLARATION_FACTS,
    label: "GSA Declaration Of Facts",
  },
  {
    value: GSA2vRegDocNames.GSA_FIDUCIARY_CONTRACT_DISTRICT_COURT,
    label: "GSA Fiduciary Contract District Court",
  },
  {
    value: GSA2vRegDocNames.GSA_FIDUCIARY_CONTRACT_IRS,
    label: "GSA Fiduciary Contract Irs",
  },
  {
    value: GSA2vRegDocNames.GSA_FIDUCIARY_CONTRACT_DTC,
    label: "GSA Fiduciary Contract Dtc",
  },
  {
    value: GSA2vRegDocNames.GSA_FIDUCIARY_CONTRACT_TREASURY,
    label: "GSA Fiduciary Contract Treasury",
  },
  {
    value: GSA2vRegDocNames.AFFIDAVIT_OF_OWNERSHIP,
    label: "Affidavit Of Ownership",
  },
  {
    value: GSA2vRegDocNames.PROOF_OF_SERVICE,
    label: "Proof Of Service",
  },
  {
    value: GSA2vRegDocNames.SETT_OF_LITIGATION,
    label: "Demand For Case Closure",
  },
  {
    value: GSA2vRegDocNames.F56,
    label: "F56",
  },
  {
    value: GSA2vRegDocNames.W9,
    label: "W9",
  },
  {
    value: GSA2vRegDocNames.F1040V,
    label: "F1040V",
  },
  { value: GSA2vRegDocNames.OF91, label: "OF91" },
  { value: GSA2vRegDocNames.SF24, label: "SF24" },
  { value: GSA2vRegDocNames.SF25, label: "SF25" },
  { value: GSA2vRegDocNames.SF25A, label: "SF25A" },
  { value: GSA2vRegDocNames.SF28, label: "SF28" },
];

export const GSA_BOND_CASE_TYPE = [
  {
    label: "Civil",
    value: GsaCaseCategory.CIVIL,
  },
  {
    label: "Criminal",
    value: GsaCaseCategory.CRIMINAL,
  },
];

export const SHOW_GSA_BOND_MARGE_FIELD = [
  {
    label: "GSA UCC 1",
    value: {
      sku: Sku.GSA_V2_DOCX,
      productName: TemplateProductName.GSA_UCC1,
    },
  },
];

export const GSA_EMAIL_TYPES = [
  {
    value: "GsaBondReviewRejected",
    label: "Gsa Bond Rejected",
  },
  {
    value: "GsaBondInProcess",
    label: "Gsa Bond in Process",
  },
  {
    value: "GsaBondSubmittedToUCC",
    label: "Gsa Bond Submitted to UCC",
  },
  {
    value: "GsaBondReadyForSignature",
    label: "Gsa Bond Signature Required",
  },
  {
    value: "GsaBondSignatureReviewRejected",
    label: "Gsa Bond Signature Rejected",
  },
  {
    value: "GsaBondCompleted",
    label: "Gsa Bond Completed",
  },
];
