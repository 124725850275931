import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  getSession(key: any): any {
    return JSON.parse(window.sessionStorage.getItem(key));
  }

  setSession(key: any, value: any): void {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
}
