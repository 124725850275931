declare const $: any;

const NOTIFY_TEMPLATE =
  '<div data-notify="container" class="notification-admin col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
  '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
  '<i class="material-icons" data-notify="icon">notifications</i> ' +
  '<span data-notify="title">{1}</span> ' +
  '<span data-notify="message">{2}</span>' +
  '<div class="progress" data-notify="progressbar">' +
  '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
  "</div>" +
  '<a href="{3}" target="{4}" data-notify="url"></a>' +
  "</div>";

class NotifyUtil {
  private _template: any;
  private listOfNotificationDom: any;

  constructor(template) {
    this._template = template;
  }

  notify(type: string, msg: string) {
    this.listOfNotificationDom = $(".notification-admin");
    const removeArray = this.listOfNotificationDom.slice(0, this.listOfNotificationDom.length - 1);

    removeArray.get().map((val) => {
      $("div.notification-admin button:first").click();
    });

    setTimeout(() => {
      $.notify(
        {
          icon: "notifications",
          message: msg,
        },
        {
          type,
          timer: 45 * 1000,
          placement: {
            from: "top",
            align: "right",
          },
          template: this._template,
        },
      );
    }, 500);
  }

  info(msg: string): void {
    this.notify("info", msg);
  }

  success(msg: string): void {
    this.notify("success", msg);
  }

  warning(msg: string): void {
    this.notify("warning", msg);
  }

  danger(msg: string): void {
    this.notify("danger", msg);
  }
}

export const notify = new NotifyUtil(NOTIFY_TEMPLATE);
