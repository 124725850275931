import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MaterialModule } from "src/app/shared/material/material.module";

import { UploadProgressComponent } from "./upload-progress.component";

@NgModule({
  declarations: [UploadProgressComponent],
  imports: [CommonModule, MaterialModule],
  exports: [UploadProgressComponent],
})
export class UploadProgressModule {}
