import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "universal-comment-dialog",
  templateUrl: "./comment-dialog.component.html",
  styleUrls: ["./comment-dialog.component.scss"],
})
export class CommentDialogComponent {
  commentForm: FormGroup;

  constructor(
    private readonly _dialogRef: MatDialogRef<CommentDialogComponent>,
    private readonly _fb: FormBuilder,
  ) {
    this.commentForm = this._fb.group({
      comment: [""],
    });
  }

  onSubmit(): void {
    this._dialogRef.close(this.commentForm.value);
  }

  onClose(): void {
    this._dialogRef.close();
  }
}
