import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { isArray, isObject } from "lodash";

import { IMergedFieldsDialogData } from "src/app/models";

@Component({
  selector: "universal-merged-fields-dialog",
  templateUrl: "./merged-fields-dialog.component.html",
  styleUrls: ["./merged-fields-dialog.component.scss"],
})
export class MergedFieldsDialogComponent implements OnInit {
  mergedFields: any[];

  constructor(
    private readonly _dialogRef: MatDialogRef<MergedFieldsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IMergedFieldsDialogData,
  ) {
    this.mergedFields = [];
  }

  ngOnInit(): void {
    this.mergedFields = Object.entries(this.data.mergedFields).map(([key, value]) => {
      if (isArray(value) || isObject(value)) {
        return { key, value: JSON.stringify(value, null, 2) };
      }

      return { key, value };
    });
  }

  onClose(): void {
    this._dialogRef.close();
  }
}
