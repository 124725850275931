import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MaterialModule } from "src/app/shared";

import { MergedFieldsDialogComponent } from "./merged-fields-dialog.component";

@NgModule({
  declarations: [MergedFieldsDialogComponent],
  imports: [CommonModule, MaterialModule],
  exports: [MergedFieldsDialogComponent],
})
export class MergedFieldsDialogModule {}
