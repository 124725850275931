import { Routes } from "@angular/router";

import { ROLE } from "./models";
import { AuthGuard, NoAuthGuard, RoleGuard } from "./providers/guards";

import { AuthLayoutComponent, AdminLayoutComponent, PageNotFoundComponent } from "./layouts";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "dashboard",
        data: {
          roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN, ROLE.SPC_ADMIN, ROLE.TRUST_ADMIN],
        },
        canActivate: [AuthGuard, RoleGuard],
        loadChildren: () => import("./containers/dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "spc-process",
        data: { roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN, ROLE.SPC_ADMIN] },
        canActivate: [AuthGuard, RoleGuard],
        loadChildren: () =>
          import("./containers/spc-process/spc-process.module").then((m) => m.SpcProcessModule),
      },
      {
        path: "trust-process",
        data: {
          roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN, ROLE.TRUST_ADMIN],
        },
        canActivate: [AuthGuard, RoleGuard],
        loadChildren: () =>
          import("./containers/trust-process/trust-process.module").then((m) => m.TrustProcessModule),
      },
      {
        path: "nda",
        data: {
          roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN, ROLE.SPC_ADMIN, ROLE.TRUST_ADMIN],
        },
        canActivate: [AuthGuard, RoleGuard],
        loadChildren: () => import("../app/containers/services/nda/nda.module").then((m) => m.NdaModule),
      },
      {
        path: "irvic",
        data: {
          roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN, ROLE.SPC_ADMIN, ROLE.TRUST_ADMIN],
        },
        canActivate: [AuthGuard, RoleGuard],
        loadChildren: () => import("../app/containers/irvic/irvic.module").then((m) => m.IrvicModule),
      },
      {
        path: "services",
        data: { roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN, ROLE.SPC_ADMIN] },
        canActivate: [AuthGuard, RoleGuard],
        loadChildren: () => import("./containers/services/services.module").then((m) => m.ServicesModule),
      },
      {
        path: "settings",
        data: { roles: [ROLE.SUPER_ADMIN, ROLE.SYSTEM_ADMIN, ROLE.TEMPLATE, ROLE.SETTINGS] },
        canActivate: [AuthGuard, RoleGuard],
        loadChildren: () => import("./containers/settings/settings.module").then((m) => m.SettingsModule),
      },
      {
        path: "user-management",
        data: { roles: [ROLE.SUPER_ADMIN] },
        canActivate: [AuthGuard, RoleGuard],
        loadChildren: () =>
          import("./containers/user-management/user-management.module").then((m) => m.UserManagementModule),
      },
    ],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        canActivate: [NoAuthGuard],
        loadChildren: () => import("./containers/auth/auth.module").then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
];
