import { MasterBondDocNames, MasterBondProductStatus, Sku, TemplateProductName } from "../enums";

export const MASTER_BOND_REVIEW_PRODUCT_STATUS_LIST = [MasterBondProductStatus.UCC];

export const MASTER_BOND_READY_PRODUCT_STATUS_LIST = [
  MasterBondProductStatus.ACCEPTED,
  MasterBondProductStatus.DOWNLOADED,
  MasterBondProductStatus.DELIVERED,
];

export const MASTER_BOND_APPROVAL_STATUS = [
  MasterBondProductStatus.DATA_REVIEW,
  MasterBondProductStatus.DOC1_SIGN_REVIEW,
];

export const MASTER_BOND_DOWNLOAD_DOCS = [
  {
    value: MasterBondDocNames.MASTER_BOND_COVER_LETTER,
    label: "Master Bond Cover Latter",
    Sku: Sku.MASTER_BOND_COVER_LETTER,
  },
  {
    value: MasterBondDocNames.MASTER_BOND,
    label: "Master Bond",
    Sku: Sku.MASTER_BOND,
  },
  {
    value: MasterBondDocNames.MASTER_BOND_FILING,
    label: "Master Bond UCC Filing",
    Sku: Sku.MASTER_BOND,
  },
  {
    value: MasterBondDocNames.MASTER_BOND_REPORT,
    label: "Master Bond UCC Report",
    Sku: Sku.MASTER_BOND,
  },
];

export const MASTER_BOND_DOCS = [
  {
    value: MasterBondDocNames.MASTER_BOND_COVER_LETTER,
    label: "Master Bond Cover Latter",
    Sku: Sku.MASTER_BOND_COVER_LETTER,
  },
  {
    value: MasterBondDocNames.MASTER_BOND,
    label: "Master Bond",
    Sku: Sku.MASTER_BOND,
  },
];

export const SHOW_MASTER_MERGE_FIELD = [
  {
    label: "Master Bond UCC",
    value: {
      sku: Sku.MASTER_BOND,
      productName: TemplateProductName.MASTER_BOND,
    },
  },
];

export const MASTER_BOND_EMAIL_TYPES = [
  {
    value: "MasterBondReviewRejected",
    label: "Master Bond Rejected",
  },
  {
    value: "MasterBondInProcess",
    label: "Master Bond in Process",
  },
  {
    value: "MasterBondSubmittedToUCC",
    label: "Master Bond Submitted to UCC",
  },
  {
    value: "MasterBondReadyForSignature",
    label: "Master Bond Signature Required",
  },
  {
    value: "MasterBondSignatureReviewRejected",
    label: "Master Bond Signature Rejected",
  },
  {
    value: "MasterBondCompleted",
    label: "Master Bond Completed",
  },
];
