export enum TrustStatus {
  FREE = "Free",
  IN_PROCESS = "In process",
  IN_REVIEW = "Trust In Review",
  SUBMITTED_TO_UCC = "Trust Submitted to UCC",
  SIGNATURE_REQUIRED = "Signatures Required",
  SIGNATURE_REVIEW = "Signature Review",
  SIGNATURE_REJECTED = "TrustSignatureReviewRejected",
  COMPLETED = "Trust Completed",
  PROTECTOR_CHANGE = "Protector Change",
  TRUSTEE_CHANGE = "Trustee Change",
  BENEFICIARY_ADD = "Beneficiary Add",
  BENEFICIARY_REPLACE = "Beneficiary Replace",
  BENEFICIARY_REMOVED = "Beneficiary Removed",
  PROTECTOR_VERIFIED = "Protector Verified",
  TRUSTEE_VERIFIED = "Trustee Verified",
  BENEFICIARY_ADD_VERIFIED = "Beneficiary Add Verified",
  BENEFICIARY_REPLACE_VERIFIED = "Beneficiary Replace Verified",
  BENEFICIARY_REMOVED_VERIFIED = "Beneficiary Removed Verified",
  PA_SENT_TO_UCC = "PA Sent to UCC",
  TRE_SENT_TO_UCC = "TRE Sent to UCC",
  BFA_SENT_TO_UCC = "BFA Sent to UCC",
  BF_ADD_SENT_TO_UCC = "BFAdd Sent to UCC",
  BF_R_SENT_TO_UCC = "BFR Sent to UCC",
}

export enum TrustPdfType {
  ORIGIN = "origin",
  CHANGED = "changed",
}

export enum TrustHumanType {
  GRANTOR = "grantor",
  PROTECTOR = "protector",
  SECONDARY_TRUSTEE = "secondaryTrustee",
  BENEFICIARY_HUMAN = "beneficiaryHuman",
}
