import { Injectable } from "@angular/core";

import { BehaviorSubject, Subject } from "rxjs";

import { IDoc, IPdf } from "src/app/models";

@Injectable({
  providedIn: "root",
})
export class OverlayService {
  private _isShowUploadProgress$: Subject<boolean> = new Subject();
  isShowUploadProgress = this._isShowUploadProgress$.asObservable();

  private _uploadingPercent$: Subject<number> = new Subject();
  uploadingPercent = this._uploadingPercent$.asObservable();

  private _uploadingFilename$: BehaviorSubject<string> = new BehaviorSubject(null);
  uploadingFilename = this._uploadingFilename$.asObservable();

  private _pdf$: Subject<IPdf> = new Subject();
  pdf = this._pdf$.asObservable();

  private _doc$: Subject<IDoc> = new Subject();
  doc = this._doc$.asObservable();

  showUploadProgress(): void {
    this._isShowUploadProgress$.next(true);
  }

  hideUploadProgress(): void {
    this._isShowUploadProgress$.next(false);
  }

  setUploadingPercent(percent: number): void {
    this._uploadingPercent$.next(percent);
  }

  setUploadingFilename(filename: string): void {
    this._uploadingFilename$.next(filename);
  }

  setPdf(pdf: IPdf): void {
    this._pdf$.next(pdf);
  }

  setDoc(doc: IDoc): void {
    this._doc$.next(doc);
  }
}
