import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

import { combineLatest, Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { NO_CB } from "src/app/models";

import { OverlayService } from "src/app/providers/services";

@Component({
  selector: "universal-upload-progress",
  templateUrl: "./upload-progress.component.html",
  styleUrls: ["./upload-progress.component.scss"],
})
export class UploadProgressComponent implements OnInit, OnDestroy {
  private _stop$: Subject<any>;

  filename: string;
  percent: number;

  constructor(
    public dialogRef: MatDialogRef<UploadProgressComponent>,
    private readonly _overlayService: OverlayService,
  ) {
    this._setVariables();
  }

  ngOnInit(): void {
    combineLatest([
      this._overlayService.isShowUploadProgress.pipe(tap(this.setStatus.bind(this))),
      this._overlayService.uploadingFilename.pipe(tap(this.setFilename.bind(this))),
      this._overlayService.uploadingPercent.pipe(tap(this.setPercent.bind(this))),
    ])
      .pipe(takeUntil(this._stop$))
      .subscribe(NO_CB);
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  private _setVariables(): void {
    this._stop$ = new Subject();
    this.filename = "";
    this.percent = 0;
  }

  setStatus(isShow: boolean): void {
    if (!isShow) {
      this.dialogRef.close();
    }
  }

  setFilename(filename: string): void {
    this.filename = filename;
  }

  setPercent(percent: number): void {
    this.percent = percent;
  }
}
